import React, { useState, useEffect } from "react";
import mqtt from "mqtt";

export default function MQTTv2() {
  const [number, setNumber] = useState(1);
  const [command, setCommnad] = useState("");
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [count, setCount] = useState(0);

  const serialPort = "/dev/ttyS5";
  const bankPort = "/dev/ttyS3";

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe("SERVER_TEST", { qos }, (err) => {
        latestTime();
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, []);

  // Convert a hexadecimal string to an array of bytes
  const hexStringToBytes = (hex) => {
    return hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16));
  };

  // Calculate checksum using XOR on an array of bytes
  const checksumXOR = (bytes) => {
    return bytes.reduce(
      (accumulator, currentValue) => accumulator ^ currentValue,
      0
    );
  };

  // Convert a byte to a two-character hexadecimal string, padded with leading zero if necessary
  const toHexString = (byte) => {
    return byte.toString(16).toUpperCase().padStart(2, "0");
  };

  // Build command string for a given slot
  const buildCommandSend = (slot) => {
    // Convert slot number to a two-character hexadecimal string, padded with leading zero if necessary
    const formattedSlot = parseInt(slot)
      .toString(16)
      .toUpperCase()
      .padStart(2, "0");

    // Construct the hex string with the given slot
    const hexString = `FF01${formattedSlot}02000000`;

    // Convert the hex string to an array of bytes
    const bytes = hexStringToBytes(hexString);

    // Calculate the checksum using XOR
    const checksum = checksumXOR(bytes);

    // Return the complete command string with the checksum appended
    return `${hexString}${toHexString(checksum)}`;
  };

  const handleSendCommand = () => {
    if (client) {
      const command = `${serialPort}:${buildCommandSend(2)}`;
      console.log(command);
      client.publish("DEVICE_TEST", command, { qos });
      latestTime();
    } else {
      console.error("MQTT client not initialized");
    }
  };

  useEffect(() => {
    const cmdInterval = setInterval(() => {
      handleSendCommand();
      setCount((prevCount) => prevCount + 1); // Correctly update count state
    }, 5000);

    // Cleanup function to clear the interval
    return () => clearInterval(cmdInterval);
  }, [client]); // Add client to the dependency array to ensure it is initialized
  const latestTime = () => {
    const now = new Date();
    let hours = String(now.getHours()).padStart(2, "0");
    let minutes = String(now.getMinutes()).padStart(2, "0");
    let seconds = String(now.getSeconds()).padStart(2, "0");
    console.log(`${hours}:${minutes}:${seconds}`);
  };
  return (
    <div>
      <input
        placeholder="hah"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
      />
      <div>{command}</div>
      <div style={{ fontSize: 50 }}>
        Counting send command test[MQTT]:{count} times
      </div>
      <div onClick={handleSendCommand}>SEND</div>
    </div>
  );
}
