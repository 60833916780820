import React from "react";

function SlotsContainer({ allSlots, setSlotArr, onSlotClick, selectedSlot }) {
  const handleSlotClick = (slotNumber) => {
    onSlotClick(slotNumber);
  };

  return (
    <div className="slots">
      {allSlots.map((slotObj) => {
        const slotAvailable =
          setSlotArr.find((item) => item.slot === slotObj.slot) === undefined;
        const isSelected = selectedSlot === slotObj.slot;
        const slotStyle = {
          backgroundColor: isSelected
            ? "#333A73"
            : slotAvailable
            ? "#ccc"
            : "#31363F",
          color: isSelected ? "#FFF" : "",
          cursor: slotAvailable ? "pointer" : "not-allowed",
          // filter: slotAvailable ? "none" : "blur(1px)",
          pointerEvents: slotAvailable ? "auto" : "none",
        };

        return (
          <div
            key={slotObj.slot}
            className="slot"
            style={slotStyle}
            onClick={slotAvailable ? () => handleSlotClick(slotObj.slot) : null}
          >
            {slotObj.slot}
          </div>
        );
      })}
    </div>
  );
}

export default SlotsContainer;
