import React, { useEffect, useState } from "react";
import "../../scss/AdminInfoController.scss";
import axios from "axios";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaPen,
  FaPlus,
  FaRedoAlt,
  FaSearch,
  FaShoppingBag,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import MachineIdManageContainer from "./MachineIdManageContainer";

export default function InfoController() {
  const [navValue, setNavValue] = useState(0);

  //MACHINE
  const [machineId, setMachineId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [remoteId, setRemoteId] = useState("");
  const [address, setAddress] = useState("");
  const [alive, setAlive] = useState(0);
  const [status, setStatus] = useState(0);
  const [isPopUp, setisPopUp] = useState(false);
  const [machines, setMachines] = useState([]);

  //ACCOUNT
  const [acocunts, setAccounts] = useState([]);
  const [isAccount, setIsAccount] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPIN, setUserPIN] = useState(0);
  const [userAddress, setUserAddress] = useState("");
  const [userPhone, setUserPhone] = useState(0);
  const [userTeam, setUserTeam] = useState("");
  const [userId, setUserId] = useState(0);
  const [allMachineID, setAllMachineID] = useState([]);
  const [usedMachineId, setUsedMachineID] = useState([]);

  const [editing, setEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // const DBurl = "http://localhost:8080";
  // const DBurl = "https://vending-api.gtcmall.com";
  const DBurl = "https://vending-machine-api.gtcmall.com";

  useEffect(() => {
    handleGetMachines();
    handleGetUsers();
    handleGetCreatedMachineId();
    handleGetUsedMachineId();
  }, []);

  //HANDLE GET CREATED MACHINEID
  const handleGetCreatedMachineId = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getCreatedMacineID`
      );
      console.log(res.data);
      setAllMachineID(res.data.result);
    } catch (error) {
      console.error(`Error when getting created machineId ${error}`);
    }
  };
  //HANDLE GET USED MACHINEID
  const handleGetUsedMachineId = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getUsedMachineId`
      );
      console.log(res.data);
      setUsedMachineID(res.data.result);
    } catch (error) {
      console.error(`Error when getting created machineId ${error}`);
    }
  };
  //GET MachineId CLICKED
  const handleMachineIdClick = (machineId) => {
    setMachineId(machineId);
  };
  //HANDLE GET MACHINES
  const handleGetMachines = async () => {
    try {
      const res = await axios.get(`${DBurl}/vending-machine-api/getMachines`);
      setMachines(res.data.result);
    } catch (error) {
      console.error("Error at getting machines", error);
    }
  };
  //HANDLE GET ACCOUNTS
  const handleGetUsers = async () => {
    try {
      const res = await axios.get(`${DBurl}/vending-machine-api/getAccount`);
      setAccounts(res.data.result);
    } catch (error) {
      console.error(`Error when getting users ${error}`);
    }
  };
  //HANDLE CREATE MACHINE
  const handleCreateMachine = async () => {
    try {
      const res = await axios.post(
        `${DBurl}/vending-machine-api/createMachine`,
        {
          machineId: machineId,
          transactionId: transactionId,
          machineAddress: address,
          machineAlive: 0,
          machineStatus: 0,
          agencyId: agencyId,
          remoteId: remoteId,
        }
      );
      console.log(res.data);
      setisPopUp(false);
      handleGetMachines();
      setMachineId("");
      setTransactionId("");
      setAddress("");
      setAgencyId("");
      setRemoteId("");
    } catch (error) {
      console.log("Error when creating machine", error);
    }
  };
  //HANDLE CREATE MACHINE
  const handleCreateUser = async () => {
    try {
      const res = await axios.post(
        `${DBurl}/vending-machine-api/createAccount`,
        {
          machineId: machineId,
          userTeam: userTeam,
          userName: userName,
          userPassword: userPassword,
          userPIN: userPIN,
          userAddress: userAddress,
          userPhone: userPhone,
        }
      );
      console.log(res.data);
      handleGetUsers();
      handleGetUsedMachineId();
      setIsAccount(false);
      setUserTeam("");
      setUserName("");
      setUserPassword("");
      setUserPIN("");
      setUserAddress("");
      setUserPhone("");
      setMachineId("");
    } catch (error) {
      console.log("Error when creating machine", error);
    }
  };
  //HANDLE EDIT MACHINE
  const handleEdit = (items) => {
    setEditing(true);
    if (navValue === 1) {
      setisPopUp(true);
      setMachineId(items.machineId);
      setTransactionId(items.transactionId);
      setAgencyId(items.agencyId);
      setRemoteId(items.remoteId);
      setAddress(items.machineAddress);
      setStatus(items.machineStatus);
      setAlive(items.machineAlive);
    } else {
      setIsAccount(true);
      handleGetCreatedMachineId();
      setUserId(items.userId);
      setUserTeam(items.userTeam);
      setUserName(items.userName);
      setUserPassword(items.userPassword);
      setUserPIN(items.userPIN);
      setUserAddress(items.userAddress);
      setUserPhone(items.userPhone);
      setMachineId(items.machineId);
    }
  };
  const handleEditMachine = async () => {
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachine`,
        {
          transactionId: transactionId,
          agencyId: agencyId,
          remoteId: remoteId,
          machineAddress: address,
          machineAlive: alive,
          machineStatus: status,
          machineId: machineId,
        }
      );
      console.log(res.data);
      setisPopUp(false);
      handleGetMachines();
      setMachineId("");
      setTransactionId("");
      setAddress("");
      setAgencyId("");
      setRemoteId("");
    } catch (error) {
      // Enhanced error logging
      if (error.response) {
        console.log(
          `Error when updating machine: ${error.response.data.message}`
        );
      } else if (error.request) {
        console.log(
          "Error when updating machine: No response received from server"
        );
      } else {
        console.log(`Error when updating machine: ${error.message}`);
      }
    }
  };
  const handleEditAccount = async () => {
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateAccount`,
        {
          machineId: machineId,
          userTeam: userTeam,
          userName: userName,
          userPassword: userPassword,
          userPIN: userPIN,
          userAddress: userAddress,
          userPhone: userPhone,
          userId: userId,
        }
      );

      console.log(res.data);
      setIsAccount(false);
      handleGetUsers();
      handleGetUsedMachineId();
      setUserTeam("");
      setUserName("");
      setUserPassword("");
      setUserPIN("");
      setUserAddress("");
      setUserPhone("");
      setMachineId("");
    } catch (error) {
      // Enhanced error logging
      if (error.response) {
        console.log(
          `Error when updating machine: ${error.response.data.message}`
        );
      } else if (error.request) {
        console.log(
          "Error when updating machine: No response received from server"
        );
      } else {
        console.log(`Error when updating machine: ${error.message}`);
      }
    }
  };
  //HANDLE DELETE MACHINE
  const handleDeleteMachine = async (machine) => {
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteMachine`,
        {
          data: { machineId: machine.machineId },
        }
      );
      console.log(res.data);
      handleGetMachines();
    } catch (error) {
      // Enhanced error logging
      if (error.response) {
        console.log(
          `Error when deleting machine: ${error.response.data.message}`
        );
      } else if (error.request) {
        console.log(
          "Error when deleting machine: No response received from server"
        );
      } else {
        console.log(`Error when deleting machine: ${error.message}`);
      }
    }
  };
  //HANDLE DELETE ACCOUNT
  const handleDeleteAccount = async (account) => {
    console.log(account.userId);
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteAccount`,
        {
          data: { userId: account.userId },
        }
      );
      console.log(res.data);
      handleGetUsers();
    } catch (error) {
      console.error(`Getting Error when delete user ${error}`);
    }
  };

  //SEARCH
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredAccounts = acocunts.filter((account) =>
    Object.values(account).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const filteredMachine = machines.filter((machine) =>
    Object.values(machine).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="info__container">
      <div className="info__content">
        <div className="headline">
          <div className="navbar">
            <div
              className={`navbar__item ${
                navValue === 0 ? "active" : "inactive"
              }`}
              onClick={() => {
                setNavValue(0);
                handleGetCreatedMachineId();
                handleGetUsedMachineId();
              }}
            >
              Accounts
            </div>
            <div
              className={`navbar__item ${
                navValue === 1 ? "active" : "inactive"
              }`}
              onClick={() => setNavValue(1)}
            >
              Machines
            </div>
          </div>
          <div className="navbar__search">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {searchQuery !== "" && (
              <div className="search__icon" onClick={() => setSearchQuery("")}>
                <FaTimes />
              </div>
            )}
          </div>
        </div>
        {navValue === 0 ? (
          <div className="info">
            <div className="info__nav">
              <div className="nav__item add" onClick={() => setIsAccount(true)}>
                <FaPlus />
              </div>
              <div className="nav__item refresh" onClick={handleGetUsers}>
                <FaRedoAlt />
              </div>
            </div>
            <div className="info__list">
              <table className="table">
                <thead className="info__headline">
                  <tr>
                    <th className="headline__item">Name</th>
                    <th className="headline__item">Email</th>
                    <th className="headline__item">Password</th>
                    <th className="headline__item">PIN</th>
                    <th className="headline__item">Address</th>
                    <th className="headline__item">Phone number</th>
                    <th className="headline__item">Machine ID</th>
                    <th className="headline__item" colSpan={2}>
                      Controller
                    </th>
                  </tr>
                </thead>
                <tbody className="info__body">
                  {filteredAccounts.map((account) => (
                    <tr className="body__item" key={account.userId}>
                      <td className="item__title">{account.userTeam}</td>
                      <td className="item__title">{account.userName}</td>
                      <td className="item__title">{account.userPassword}</td>
                      <td className="item__title">{account.userPIN}</td>
                      <td className="item__title">{account.userAddress}</td>
                      <td className="item__title">{account.userPhone}</td>
                      <td className="item__title">{account.machineId}</td>
                      <td
                        className="item__title"
                        onClick={() => handleEdit(account)}
                      >
                        <FaPen />
                      </td>
                      <td
                        className="item__title"
                        onClick={() => handleDeleteAccount(account)}
                      >
                        <FaTrash />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : navValue === 1 ? (
          <div className="info">
            <div className="info__nav">
              <div className="nav__item add" onClick={() => setisPopUp(true)}>
                <FaPlus />
              </div>
              <div className="nav__item refresh" onClick={handleGetMachines}>
                <FaRedoAlt />
              </div>
            </div>
            <div className="info__list">
              <table className="table">
                <thead className="info__headline">
                  <tr>
                    <th className="headline__item">Machine ID</th>
                    <th className="headline__item">Transaction ID</th>
                    <th className="headline__item">Agency ID</th>
                    <th className="headline__item">Remote ID</th>
                    <th className="headline__item">Address</th>
                    <th className="headline__item">Status</th>
                    <th className="headline__item">Alive</th>
                    <th className="headline__item" colSpan={2}>
                      Controller
                    </th>
                  </tr>
                </thead>
                <tbody className="info__body">
                  {filteredMachine.map((machine) => (
                    <tr className="body__item" key={machine.machineId}>
                      <td className="item__title">{machine.machineId}</td>
                      <td className="item__title">{machine.transactionId}</td>
                      <td className="item__title">{machine.agencyId}</td>
                      <td className="item__title">{machine.remoteId}</td>
                      <td className="item__title">{machine.machineAddress}</td>
                      <td className="item__title">
                        {machine.machineStatus === 1 ? (
                          <div className="active">
                            <FaShoppingBag />
                          </div>
                        ) : (
                          <div className="inactive"></div>
                        )}
                      </td>
                      <td className="item__title">
                        {machine.machineAlive === 1 ? (
                          <div className="offline">
                            <FaExclamationCircle />
                          </div>
                        ) : (
                          <div className="online">
                            <FaCheckCircle />
                          </div>
                        )}
                      </td>
                      <td
                        className="item__title"
                        onClick={() => handleEdit(machine)}
                      >
                        <FaPen />
                      </td>
                      <td
                        className="item__title"
                        onClick={() => handleDeleteMachine(machine)}
                      >
                        <FaTrash />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isPopUp ? (
        <div className="info__alert">
          <div className="alert__content">
            {editing ? (
              <></>
            ) : (
              <div className="input__field">
                <div className="field__headline">Machine ID</div>
                <input
                  value={machineId}
                  onChange={(e) => setMachineId(e.target.value)}
                />
              </div>
            )}
            <div className="input__field">
              <div className="field__headline">Transaction ID</div>
              <input
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </div>
            <div className="input__field">
              <div className="field__headline">Agency ID</div>
              <input
                value={agencyId}
                onChange={(e) => setAgencyId(e.target.value)}
              />
            </div>
            <div className="input__field">
              <div className="field__headline">Remote ID</div>
              <input
                value={remoteId}
                onChange={(e) => setRemoteId(e.target.value)}
              />
            </div>
            <div className="input__field">
              <div className="field__headline">Machine Address</div>
              <input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            {editing ? (
              <div className="input__field">
                <div className="field__headline">Machine Status</div>
                <input
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                />
              </div>
            ) : (
              <></>
            )}
            {editing ? (
              <div className="input__field">
                <div className="field__headline">Machine Alive</div>
                <input
                  value={alive}
                  onChange={(e) => setAlive(e.target.value)}
                />
              </div>
            ) : (
              <></>
            )}
            {editing ? (
              <div className="alert__btn" onClick={handleEditMachine}>
                change now
              </div>
            ) : (
              <div className="alert__btn" onClick={handleCreateMachine}>
                add new
              </div>
            )}
            <div
              className="alert__close"
              onClick={() => {
                handleGetMachines();
                setMachineId("");
                setTransactionId("");
                setAddress("");
                setAgencyId("");
                setRemoteId("");
                setisPopUp(false);
              }}
            >
              <FaTimes />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isAccount ? (
        <div className="info__alert">
          <div className="alert__content">
            <div className="input__field">
              <div className="field__headline">Name</div>
              <input
                value={userTeam}
                onChange={(e) => setUserTeam(e.target.value)}
              />
            </div>
            <div className="input__field">
              <div className="field__headline">Email</div>
              <input
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="input__field">
              <div className="field__headline">Password</div>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
              />
            </div>
            <div className="input__field">
              <div className="field__headline">PIN</div>
              <input
                value={userPIN}
                onChange={(e) => setUserPIN(e.target.value)}
              />
            </div>
            <div className="input__field">
              <div className="field__headline">Address</div>
              <input
                value={userAddress}
                onChange={(e) => setUserAddress(e.target.value)}
              />
            </div>
            <div className="input__field">
              <div className="field__headline">Phone number</div>
              <input
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
              />
            </div>
            <div className="input__field">
              <div className="field__headline">Machine ID</div>
              <MachineIdManageContainer
                allMachineID={allMachineID}
                setUsedMachineID={usedMachineId}
                onMachineIdClick={handleMachineIdClick}
                selectedMachineId={machineId}
              />
            </div>
            {editing ? (
              <div className="alert__btn" onClick={handleEditAccount}>
                change now
              </div>
            ) : (
              <div className="alert__btn" onClick={handleCreateUser}>
                add new
              </div>
            )}
            <div
              className="alert__close"
              onClick={() => {
                handleGetUsers();
                setIsAccount(false);
                setUserTeam("");
                setUserName("");
                setUserPassword("");
                setUserPIN("");
                setUserAddress("");
                setUserPhone("");
              }}
            >
              <FaTimes />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
