import React from "react";

function MachineIdManageContainer({
  allMachineID,
  setUsedMachineID,
  onMachineIdClick,
  selectedMachineId,
}) {
  const handleMachineIdClick = (slotNumber) => {
    onMachineIdClick(slotNumber);
  };

  return (
    <div className="machineID__list">
      {allMachineID.map((machineObj) => {
        const machineIdAvailable =
          setUsedMachineID.find(
            (item) => item.machineId === machineObj.machineId
          ) === undefined;
        const isSelected = selectedMachineId === machineObj.machineId;
        const machineIdStyle = {
          backgroundColor: isSelected
            ? "#FF7E36"
            : machineIdAvailable
            ? "#ccc"
            : "#344C64",
          color: isSelected ? "#FFF" : "",
          cursor: machineIdAvailable ? "pointer" : "not-allowed",
          // filter: machineIdAvailable ? "none" : "blur(1px)",
          pointerEvents: machineIdAvailable ? "auto" : "none",
        };

        return (
          <div
            key={machineObj.machineId}
            className="machineID"
            style={machineIdStyle}
            onClick={
              machineIdAvailable
                ? () => handleMachineIdClick(machineObj.machineId)
                : null
            }
          >
            {machineObj.machineId}
          </div>
        );
      })}
    </div>
  );
}

export default MachineIdManageContainer;
