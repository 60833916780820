import React, { useEffect, useState } from "react";
import "../scss/Home.scss";
import { FaBox, FaExclamation, FaList, FaMoneyBill } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import Product from "./Product";
import CountDown from "./CountDown";
import axios from "axios";
import mqtt from "mqtt";

function HomePage(props) {
  const [products, setProducts] = useState([]);
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [numberA, setNumerA] = useState(0);
  const [b, setB] = useState(10); // Use state for b
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");
  const [isSetReturn, setIsSetReturn] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [returnedCmdValue, setReturnedCmdValue] = useState(0);
  const [machineInfo, setMachineInfo] = useState([]);

  const { state } = useLocation();
  const balance = state?.balance;
  const machineId = state?.machineId;
  const agencyId = state?.agencyId;
  const token = state?.token;
  const loginInfo = state?.loginInfo;

  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  // const DBurl = "https://vending-api.gtcmall.com";
  const DBurl = "https://vending-machine-api.gtcmall.com";
  // const DBurl = "http://localhost:8080";

  const bankPort = "/dev/ttyS3";

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  //LOAD LOCALSTORAGE DATA PUB SUB
  useEffect(() => {
    // Load data from local storage on component mount
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopicStorage(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopicStorage(pubTopicStoredData);
    }
    const returnedCmdStoredData = localStorage.getItem("8rl92hwckg");
    if (returnedCmdStoredData) {
      setReturnedCmdValue(returnedCmdStoredData);
    }
  }, []);

  useEffect(() => {
    if (response === "/dev/ttyS3:010003000103") {
      setIsSetReturn(true);
    }
  }, [response]);

  //MQTT
  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopicStorage, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const now = new Date();
        let hours = String(now.getHours()).padStart(2, "0");
        let minutes = String(now.getMinutes()).padStart(2, "0");
        let seconds = String(now.getSeconds()).padStart(2, "0");
        let current = `${hours}:${minutes}:${seconds}`;
        console.log(current);
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        setNumerA(1);
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [subTopicStorage, pubTopicStorage]);

  //SET RETURN MONEY
  useEffect(() => {
    if (client) {
      // getProductsByMachine();
      getMachineInfo();
      handleGTCAPI();
      handleGetProductById();
      setTimeout(() => {
        handleSetReturn();
      }, 5000);
    }
  }, [client]);
  //HANDLE FORMAT NUMBER
  const handelFormat = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  const handleGetProductById = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/gtc/selling-machine/gettingProduct`,
        {
          params: {
            agencyId: agencyId,
          },
        }
      );
      setProducts(res.data.result.result.data);
      // console.log(res.data.result.result.data);
    } catch (error) {
      console.error(`Error when getting machine info ${error}`);
    }
  };
  // //HANDLE GET PRODUCTS BY PASSIN MACHINEID
  // const getProductsByMachine = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${DBurl}/vending-machine-api/getProductByMachine`,
  //       {
  //         params: {
  //           machineId: machineId,
  //         },
  //       }
  //     );
  //     setProducts(res.data);
  //   } catch (err) {
  //     console.error("Error fetching data:", err);
  //   }
  // };
  //HANDLE GET MACHINE INFO
  const getMachineInfo = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getMachineById`,
        {
          params: {
            machineId: machineId,
          },
        }
      );
      setMachineInfo(res.data);
    } catch (error) {
      console.error(`Error when getting machine info ${error}`);
    }
  };
  //HANDLE RESET MACHINE STATUS
  const resetMachineStatus = async () => {
    console.log("RESETED");
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachineStatus/${machineId}`,
        {
          machineStatus: 0,
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };
  //HANDLE REMOVE TOKEN
  const handleRemoveToken = async () => {
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteToken`,
        {
          data: {
            token: token,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.error("Error when delete token: ", err);
    }
  };
  //SET RETURN MONEY
  const handleSetReturn = () => {
    // Convert a hexadecimal string to an array of bytes
    const hexStringToBytes = (hex) => {
      return hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16));
    };
    // Calculate checksum using XOR on an array of bytes
    const checksumXOR = (bytes) => {
      return bytes.reduce(
        (accumulator, currentValue) => accumulator ^ currentValue,
        0
      );
    };
    // Convert a byte to a two-character hexadecimal string, padded with leading zero if necessary
    const toHexString = (byte) => {
      return byte.toString(16).toUpperCase().padStart(2, "0");
    };
    // Build command string for withdraw -- DISABLE
    const buildCommandSendWithDraw = (number) => {
      // Convert slot number to a two-character hexadecimal string, padded with leading zero if necessary
      const formattedSlot = parseInt(number)
        .toString(16)
        .toUpperCase()
        .padStart(2, "0");
      // Construct the hex string with the given slot
      const hexString = `0301010017${formattedSlot}`;

      // Convert the hex string to an array of bytes
      const bytes = hexStringToBytes(hexString);

      // Calculate the checksum using XOR
      const checksum = checksumXOR(bytes);

      // Return the complete command string with the checksum appended
      return `${hexString}${toHexString(checksum)}`;
    };
    console.log("set return money");
    const command = `${bankPort}:${buildCommandSendWithDraw(returnedCmdValue)}`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
  };
  //transfer
  const handleTransfer = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/transfer-v2`, {
        token: token,
        value: 0,
        machineId: machineId,
        agencyId: agencyId,
        time: getCurrentTime(),
      });
      console.log(res.data.result.success);
      // setCompletedStatus(res.data.result.success);
    } catch (error) {
      navigateToPage("/trans-failed");
    }
  };
  //callback
  const handleGTCAPI = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/callback`, {
        token: token,
        balance: balance,
        machineId: machineId,
      });
      setIsTokenValid(res.data.result);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  //logout
  const handleLogOutGTC = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/logout`, {
        token: token,
        machineId: machineId,
      });

      console.log(res);
    } catch (error) {
      console.error(`Error when getting machine info ${error}`);
    }
  };

  const getCurrentTime = () => {
    let currentDateTime = new Date();

    // Get the year, month, and day components
    let year = currentDateTime.getFullYear();
    // Months are zero-based, so we add 1 to get the correct month
    let month = currentDateTime.getMonth() + 1;
    let day = currentDateTime.getDate();

    // Pad single-digit months and days with a leading zero if needed
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Get the hour, minute, and second components
    let hour = currentDateTime.getHours();
    let minute = currentDateTime.getMinutes();
    let second = currentDateTime.getSeconds();

    // Pad single-digit hour, minute, and second with a leading zero if needed
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    let formattedTime = `${hour}:${minute}:${second}`;

    let time = `${year}-${month}-${day} ${formattedTime}`;

    return time;
  };

  return (
    <div className="home__container">
      <div className="home__content">
        <div className="header">
          <div className="header__headline">
            <div className="headline__box"></div>
            <p className="headline__text">
              Hôm nay
              <br />
              bạn muốn mua gì ?
            </p>
          </div>
          <div className="header__balance">
            <div className="balance__number">
              {handelFormat(balance)}
              <span className="currency">gt</span>
            </div>
          </div>
        </div>
        <Product
          data={products}
          balance={balance}
          machineId={machineId}
          agencyId={agencyId}
          token={token}
          loginInfo={loginInfo}
        />
      </div>
      <div className="state">
        <div className="state__item active">
          <FaBox className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item ">
          <FaMoneyBill className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item ">
          <FaList className="state__icon" />
        </div>
      </div>
      <div
        onClick={() => {
          navigateToPage("/scanqrcode");
          resetMachineStatus();
          handleRemoveToken();
          handleLogOutGTC();
        }}
        className="logout"
      >
        <div>đăng xuất</div>
        <CountDown
          pathPage={"/scanqrcode"}
          data={{
            machineId: machineId,
            agencyId: agencyId,
            token: token,
            value: 0,
          }}
        />
      </div>
      <div className="transaction">
        {isSetReturn ? (
          <div
            className="transaction__item topup"
            onClick={() =>
              navigateToPage("/topup", {
                balance: balance,
                token: token,
                machineId: machineId,
                agencyId: agencyId,
                machineInfo: machineInfo,
              })
            }
          >
            <div className="transaction__headline topup">Nạp</div>
          </div>
        ) : (
          <div className="transaction__item topup">
            <div className="dots ">
              <div className="dot topup first"></div>
              <div className="dot topup second"></div>
              <div className="dot topup third"></div>
            </div>
          </div>
        )}
        {isSetReturn ? (
          <div
            className="transaction__item withdraw"
            onClick={() =>
              navigateToPage("/withdraw", {
                balance: balance,
                token: token,
                machineId: machineId,
                agencyId: agencyId,
                machineInfo: machineInfo,
              })
            }
          >
            <div className="transaction__headline withdraw">Hoàn</div>
          </div>
        ) : (
          <div className="transaction__item withdraw">
            <div className="dots ">
              <div className="dot withdraw first"></div>
              <div className="dot withdraw second"></div>
              <div className="dot withdraw third"></div>
            </div>
          </div>
        )}
      </div>
      {isTokenValid ? (
        <></>
      ) : (
        <div className="valid">
          <div className="valid__content">
            <FaExclamation className="valid__icon" />
            <div className="valid__headline">
              Phiên làm việc đã hết hạn <br /> Vui lòng đăng nhập lại để thực
              hiện giao dịch mới
            </div>
            <div
              className="valid__btn"
              onClick={() => {
                navigateToPage("/scanqrcode");
                resetMachineStatus();
                handleRemoveToken();
              }}
            >
              đăng nhập
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomePage;
