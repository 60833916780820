import React, { useEffect, useState } from "react";
import "../../scss/AdminManage.scss";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  FaGripHorizontal,
  FaLightbulb,
  FaMoneyBillWave,
  FaPenAlt,
  FaPlay,
  FaTimes,
} from "react-icons/fa";
import mqtt from "mqtt";
import SlotsContainer from "./SlotManageContainer";
import Resizer from "react-image-file-resizer";

export default function Management() {
  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  //OPEN BOXES
  const [isOpenBox, setIsOpenBox] = useState(false);
  const [userPIN, setUserPIN] = useState();
  const [isPIN, setIsPIN] = useState("");
  const [machineId, setMachineId] = useState("");
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");
  const [lightChange, setLightChange] = useState(false);

  //INSERT FUNCTION
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [price, setPrice] = useState(0);
  const [ogPrice, setOgPrice] = useState(0);
  const [slot, setSlot] = useState(0);
  const [slotArr, setSlotArr] = useState([]);
  const [error, setError] = useState("");

  //EDIT FUNCTION
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [editing, setEditing] = useState(null); // New state for editing
  const [originalImage, setOriginalImage] = useState(null); // State for original image

  //DELETE FUNCTION
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [productInfo, setproductInfo] = useState({});

  let allSlots = [
    { slot: 1 },
    { slot: 2 },
    { slot: 3 },
    { slot: 4 },
    { slot: 5 },
    { slot: 6 },
    { slot: 7 },
    { slot: 8 },
    { slot: 9 },
    { slot: 10 },
    { slot: 11 },
    { slot: 12 },
    { slot: 13 },
    { slot: 14 },
    { slot: 15 },
    { slot: 16 },
    { slot: 17 },
    { slot: 18 },
    { slot: 19 },
    { slot: 20 },
    { slot: 21 },
    { slot: 22 },
    { slot: 23 },
    { slot: 24 },
  ];
  const remainingSlots = [];

  const { state } = useLocation();
  const userInfo = state?.userInfo;

  // const DBurl = "https://vending-api.gtcmall.com";
  const DBurl = "https://vending-machine-api.gtcmall.com";
  // const DBurl = "http://localhost:8080";

  const serialPort = "/dev/ttyS5";
  const bankPort = "/dev/ttyS3";

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  //MQTT COMMUNICATION
  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopicStorage, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [pubTopicStorage, subTopicStorage]);

  //RUN ONCE WHEN COMPONENT MOUNTED
  useEffect(() => {
    // getProductsByMachine();
    getImages();
    getUsedSlots();
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopicStorage(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopicStorage(pubTopicStoredData);
    }
  }, []);

  //RESET THE SLOTS LIST EACH TIME OPEN MODAL
  useEffect(() => {
    if (isOpenModal || isOpenEdit) {
      getUsedSlots();
      allSlots.forEach((item2) => {
        if (!slotArr.some((item1) => item1.slot === item2.slot)) {
          remainingSlots.push(item2);
        }
      });
    }
  }, [isOpenModal, isOpenEdit]);

  const getImages = async () => {
    setError(null);
    try {
      const res = await axios.get(`${DBurl}/getImages`, {
        params: { machineId: userInfo.machineId },
      });
      setProducts(res.data);
    } catch (err) {
      setError("Failed to fetch images");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      Resizer.imageFileResizer(
        file,
        300, // max width
        300, // max height
        "JPEG", // format
        100, // quality
        0, // rotation
        (uri) => {
          const resizedFile = new File([uri], file.name, { type: file.type });
          setImage(resizedFile);
          setImageURL(URL.createObjectURL(uri));
        },
        "blob" // Output format
      );
    } else {
      setImage(null);
      setImageURL("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    if (!image || !price || !slot || !ogPrice || !name) {
      setError("Please fill out all fields and select an image to upload");
      return;
    }
    const formData = new FormData();
    formData.append("image", image);
    formData.append("machineId", userInfo.machineId);
    formData.append("price", price);
    formData.append("ogPrice", ogPrice);
    formData.append("slot", slot);
    formData.append("name", name);

    try {
      await axios.post(`${DBurl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setName("");
      setPrice("");
      setOgPrice("");
      setSlot("");
      setImage(null);
      setImageURL("");
      getImages(); // Refresh the image list
      setIsOpenModal(false);
    } catch (err) {
      setError("Failed to upload image");
    }
  };

  const handleEdit = (image) => {
    setEditing(image.id);
    setName(image.name);
    setPrice(image.price);
    setOgPrice(image.ogPrice);
    setSlot(image.slot);
    setOriginalImage(image.path);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setError(null);
    if (!price || !slot || !ogPrice || !name) {
      setError("Please fill out all fields");
      return;
    }
    try {
      const formData = new FormData();
      if (image) {
        formData.append("image", image);
      }
      formData.append("price", price);
      formData.append("ogPrice", ogPrice);
      formData.append("slot", slot);
      formData.append("name", name);

      await axios.put(`${DBurl}/updateImage/${editing}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setEditing(null);
      setName("");
      setPrice("");
      setOgPrice("");
      setSlot("");
      setImage(null);
      setImageURL("");
      getImages(); // Refresh the image list
      setIsOpenModal(false);
    } catch (err) {
      setError("Failed to update image");
    }
  };

  const handleDelete = async (id) => {
    setError(null);
    try {
      await axios.delete(`${DBurl}/deleteImage/${id}`);
      getImages(); // Refresh the image list
      setDeleteAlert(false);
    } catch (err) {
      setError("Failed to delete image");
    }
  };

  //GET USED SLOTS
  const getUsedSlots = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getSlotMachine`,
        {
          params: {
            machineId: userInfo.machineId,
          },
        }
      );
      setSlotArr(res.data);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  //GET SLOT
  const handleSlotClick = (slotNumber) => {
    setSlot(slotNumber);
  };

  // Convert a hexadecimal string to an array of bytes
  const hexStringToBytes = (hex) => {
    return hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16));
  };

  // Calculate checksum using XOR on an array of bytes
  const checksumXOR = (bytes) => {
    return bytes.reduce(
      (accumulator, currentValue) => accumulator ^ currentValue,
      0
    );
  };

  // Convert a byte to a two-character hexadecimal string, padded with leading zero if necessary
  const toHexString = (byte) => {
    return byte.toString(16).toUpperCase().padStart(2, "0");
  };

  // Build command string for a given slot
  const buildCommandSend = (slot) => {
    // Convert slot number to a two-character hexadecimal string, padded with leading zero if necessary
    const formattedSlot = parseInt(slot)
      .toString(16)
      .toUpperCase()
      .padStart(2, "0");

    // Construct the hex string with the given slot
    const hexString = `FF01${formattedSlot}02000000`;

    // Convert the hex string to an array of bytes
    const bytes = hexStringToBytes(hexString);

    // Calculate the checksum using XOR
    const checksum = checksumXOR(bytes);

    // Return the complete command string with the checksum appended
    return `${hexString}${toHexString(checksum)}`;
  };

  //OPEN BOX BY SLOT
  const handleOpenBox = () => {
    if (userPIN == userInfo.userPIN) {
      const commandSend = `${serialPort}:${buildCommandSend(slot)}`;
      client.publish(pubTopicStorage, commandSend, { qos });
      setIsOpenBox(false);
      setUserPIN();
      setIsPIN("");
    } else {
      setIsPIN("Incorrect PIN. Please try again!");
      setUserPIN();
    }
  };
  //HANDLE TURN ON LIGHT
  const handleTurnLightOn = () => {
    const commandSend = `${serialPort}:FF020112FFFFFF11`;
    client.publish(pubTopicStorage, commandSend, { qos });
    setLightChange(true);
  };
  //HANDLE TURN OFF LIGHT
  const handleTurnLightOff = () => {
    const commandSend = `${serialPort}:FF020112000000EE`;
    client.publish(pubTopicStorage, commandSend, { qos });
    setLightChange(false);
  };

  return (
    <div className="admin__manage_container">
      <div className="admin__manage_content">
        <div className="admin__manage_header">
          <div className="admin__manage_headline">
            <div>
              Chào mừng
              <span className="headline__user"> {userInfo.userName}</span>.
            </div>
          </div>
          {/* <div className="admin__manage_control">
            <div
              onClick={() => {
                setIsOpenModal(true);
                setName("");
                setPrice("");
                setOgPrice("");
                setSlot("");
                setImage(null);
                setImageURL("");
                setOriginalImage("");
              }}
              className="admin__manage_create"
            >
              thêm sản phẩm mới
            </div>
            <div className="admin__manage_deives">
              <div className="headline_count">
                Tổng: {products.length} sản phẩm
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="admin__manage_list">
          {products.length > 0 ? (
            <>
              {products.map((product) => (
                <div className="item" key={product.productId}>
                  <img
                    className="item__image"
                    src={`${DBurl}${product.path}`}
                    alt="Uploaded"
                  />
                  <div className="item__info">
                    <div className="item__name">{product.name}</div>
                    <div className="item__price">
                      Giá vốn: {product.ogPrice}
                    </div>
                    <div className="item__price">Giá bán: {product.price}</div>
                    <div className="item__info_bottom">
                      <div className="item__info_slot">
                        Vị trí
                        <div className="item__info_slot_number">
                          {product.slot}
                        </div>
                      </div>
                      <div className="item__controller">
                        <div
                          className="controller open"
                          onClick={() => {
                            setIsOpenBox(true);
                            setSlot(product.slot);
                          }}
                        >
                          <div className="controller__icon open">mở hộp</div>
                        </div>
                        <div
                          className="controller edit"
                          onClick={() => {
                            // setIsOpenEdit(true);
                            // setproductInfo(product);
                            setIsOpenModal(true);
                            handleEdit(product);
                          }}
                        >
                          <FaPenAlt className="controller__icon edit" />
                        </div>
                        <div
                          onClick={() => {
                            setDeleteAlert(true);
                            setproductInfo(product);
                          }}
                          className="controller delete"
                        >
                          <FaTimes className="controller__icon delete" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="no__item">
              Chưa có sản phẩm nào trong cửa hàng của bạn.
            </div>
          )}
        </div> */}
        <div className="admin__controller">
          <div
            className="controller__item"
            onClick={() =>
              navigateToPage("/admin-manage-boxes", { userInfo: userInfo })
            }
          >
            <FaGripHorizontal className="item__icon" />
            <div className="item__text">Điều khiển ngăn</div>
          </div>
          <div
            className="controller__item"
            onClick={() =>
              navigateToPage("/admin-manage-machine", { userInfo: userInfo })
            }
          >
            <FaPlay className="item__icon" />
            <div className="item__text">Thiết lập máy</div>
          </div>
        </div>
        <div className="admin__controller">
          <div
            className="controller__item"
            onClick={() =>
              navigateToPage("/admin-manage-money", { userInfo: userInfo })
            }
          >
            <FaMoneyBillWave className="item__icon" />
            <div className="item__text">Thiết lập hoàn tiền</div>
          </div>
          {lightChange ? (
            <div className="controller__item" onClick={handleTurnLightOff}>
              <FaLightbulb className="item__icon" />
              <div className="item__text">Tắt đèn</div>
            </div>
          ) : (
            <div className="controller__item" onClick={handleTurnLightOn}>
              <FaLightbulb className="item__icon" />
              <div className="item__text">Sáng đèn</div>
            </div>
          )}
        </div>
      </div>
      <div className="logout" onClick={() => navigateToPage("/scanqrcode")}>
        đăng xuất
      </div>
      {isOpenModal ? (
        <div className="modal">
          <form
            onSubmit={editing ? handleUpdate : handleSubmit}
            className="modal__content"
          >
            <div className="modal__image">
              {imageURL && (
                <img src={imageURL} alt="product's image" className="image" />
              )}
              {editing && !imageURL && originalImage && (
                <img
                  src={`${DBurl}${originalImage}`}
                  alt="Original"
                  style={{ maxWidth: "100%" }}
                />
              )}
            </div>
            <div className="modal__input">
              {editing ? (
                <div className="modal__input_headline">Chỉnh sửa sản phẩm</div>
              ) : (
                <div className="modal__input_headline">Thêm mới sản phẩm</div>
              )}
              <div className="modal__input_form">
                <div className="modal__input_field">
                  <div className="field__lable">Tên</div>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="modal__input_field">
                  <div className="field__lable">Giá vốn</div>
                  <input
                    value={ogPrice}
                    onChange={(e) => setOgPrice(e.target.value)}
                    placeholder="Original Price"
                    required
                  />
                </div>
                <div className="modal__input_field">
                  <div className="field__lable">Giá bán</div>
                  <input
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Price"
                    required
                  />
                </div>
                <div className="modal__input_field">
                  <div className="field__lable">Hình ảnh</div>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    required={!editing}
                    className="modal__image_btn"
                  />
                </div>
              </div>
              <div className="modal__slot_container">
                <div className="field__lable">
                  Vị trí <span style={{ fontWeight: 600 }}>{slot}</span>
                </div>
                <div className="modal__input_slots">
                  <SlotsContainer
                    allSlots={allSlots}
                    setSlotArr={slotArr}
                    onSlotClick={handleSlotClick}
                    selectedSlot={slot}
                  />
                </div>
              </div>
              <div
                className="modal__close"
                onClick={() => {
                  setIsOpenModal(false);
                  setEditing(false);
                }}
              >
                <FaTimes className="modal__close_icon" />
              </div>
              {editing ? (
                <button className="modal__input_btn" type="submit">
                  chỉnh sửa
                </button>
              ) : (
                <button className="modal__input_btn" type="submit">
                  thêm mới
                </button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <></>
      )}
      {deleteAlert ? (
        <div className="modal">
          <div className="modal__content">
            <div className="delete__container">
              <div className="delete__header">
                Bạn có muốn xoá <br />
                <strong>{productInfo.name}</strong> ?
              </div>
              <div className="delete__controller">
                <div
                  className="delete__btn cancel"
                  onClick={() => setDeleteAlert(false)}
                >
                  Thoát
                </div>
                <div
                  className="delete__btn confirm"
                  // (item.id)
                  onClick={() => handleDelete(productInfo.id)}
                >
                  Đồng ý
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isOpenBox ? (
        <div className="modal">
          <div className="modal__content">
            <div className="open__container">
              <div className="open__header">
                Vui lòng nhập mã PIN của bạn {slot} <br />
                <span className="open__header_recommend">Mã bao gồm 6 số</span>
              </div>
              <div className="open__pin">
                <input
                  type="password"
                  placeholder="enter your PIN"
                  value={userPIN}
                  onChange={(e) => setUserPIN(e.target.value)}
                />
                <div className="open__pin_status">{isPIN}</div>
              </div>
              <div className="open__controller">
                <div
                  className="open__btn cancel"
                  onClick={() => setIsOpenBox(false)}
                >
                  Thoát
                </div>
                <div className="open__btn confirm" onClick={handleOpenBox}>
                  Đồng ý
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
