import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdsPage from "./components/AdsPage";
import HomePage from "./components/HomePage";
import QRCode from "./components/QRCodePage";
import Product from "./components/Product";
import PaymentPage from "./components/PaymentPage";
import CashPage from "./components/PAYMENT/CashPage";
import BankingPage from "./components/PAYMENT/BankingPage";
import GTCPage from "./components/PAYMENT/GTCPage";
import TransSuccess from "./components/TransSuccess";
import TransFail from "./components/TransFail";
import GTCwithCash from "./components/PAYMENT/GTCwithCash";
import GetCrypto from "./components/GetCrypto";
import LoginWithAPI from "./components/LoginWithAPI";
import Management from "./components/admin/Management";
import AdminLogin from "./components/admin/AdminLogin";
import BoxesControl from "./components/admin/BoxesControl";
import MachineConfig from "./components/admin/MachineConfig";
import Socket from "./components/TESTING/Socket";
import CashController from "./components/TESTING/CashController";
import Test from "./components/TESTING/Test";
import TechnicalConfig from "./components/admin/TechnicalConfig";
import MQTTv2 from "./components/TESTING/MQTTv2";
import TopUp from "./components/CASHING/TopUp";
import WithDraw from "./components/CASHING/WithDraw";
import InfoController from "./components/admin/InfoController";
import MoneyConfig from "./components/admin/MoneyConfig";

function App(props) {
  return (
    <BrowserRouter>
      <Routes>
        {/* USER */}
        <Route path="/" index element={<AdsPage />} />
        <Route path="/homepage" element={<HomePage />} />
        <Route path="/scanqrcode" element={<QRCode />} />
        <Route path="/test" element={<Product />} />
        <Route path="/detail/:productId" element={<PaymentPage />} />
        <Route path="/payment-cash" element={<CashPage />} />
        <Route path="/payment-banking" element={<BankingPage />} />
        <Route path="/payment-gtc" element={<GTCPage />} />
        <Route
          path="/payment-gtc/payment-gtc-with-cash"
          element={<GTCwithCash />}
        />
        <Route path="/trans-completed" element={<TransSuccess />} />
        <Route path="/trans-failed" element={<TransFail />} />
        <Route path="/getCrypto" element={<GetCrypto />} />
        {/* CASHING */}
        <Route path="/topup" element={<TopUp />} />
        <Route path="/withdraw" element={<WithDraw />} />
        {/* ADMIN */}
        <Route path="/admin-manage-login" element={<AdminLogin />} />
        <Route path="/admin-management" element={<Management />} />
        <Route path="/admin-manage-boxes" element={<BoxesControl />} />
        <Route path="/admin-manage-machine" element={<MachineConfig />} />
        <Route path="/admin-manage-tech" element={<TechnicalConfig />} />
        <Route path="/admin-manage-money" element={<MoneyConfig />} />
        <Route path="/informationController" element={<InfoController />} />
        {/* TESTING */}
        <Route path="/loginWithAPI" element={<LoginWithAPI />} />
        <Route path="/socket" element={<Socket />} />
        <Route path="/mqttv2" element={<MQTTv2 />} />
        <Route path="/cashController" element={<CashController />} />
        <Route path="/testing" element={<Test />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
