import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCheck, FaExclamation } from "react-icons/fa";
import "../scss/TransResult.scss";
import CountDown from "./CountDown";
import mqtt from "mqtt";
import axios from "axios";

export default function TransSuccess() {
  const [logout, setLogout] = useState(false);
  const [keepGoing, setKeepGoing] = useState(false);
  const [completedStatus, setCompletedStatus] = useState(null);
  const [transMade, setTransMade] = useState(false);
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");
  const [withDrawValue, setWithDrawValue] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(true);

  const { state } = useLocation();
  const machineId = state?.machineId;
  const agencyId = state?.agencyId;
  const token = state?.token;
  const value = state?.value;
  const balance = state?.balance;
  const productInfo = state?.productInfo;
  const cashAmount = state?.cashAmount;

  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  const serialPort = "/dev/ttyS5";
  const bankPort = "/dev/ttyS3";

  const DBurl = "https://vending-machine-api.gtcmall.com";
  // const DBurl = "https://vending-api.gtcmall.com";
  // const DBurl = "http://localhost:8080";

  useEffect(() => {
    // Load data from local storage on component mount
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopicStorage(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopicStorage(pubTopicStoredData);
    }
    handleGTCAPI();
  }, []);

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;
  //MQTT COMMUNICATION
  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopicStorage, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, []);
  //WHEN SEND API SUCCESSFULLY -> OPEN BOX
  // useEffect(() => {
  //   if (completedStatus) {
  //     handleSendCmd();
  //   }
  // }, [completedStatus]);

  // START UPDATE GTC SYSTEM
  useEffect(() => {
    handleTransfer();
  }, [client]);

  // Convert a hexadecimal string to an array of bytes
  const hexStringToBytes = (hex) => {
    return hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16));
  };
  // Calculate checksum using XOR on an array of bytes
  const checksumXOR = (bytes) => {
    return bytes.reduce(
      (accumulator, currentValue) => accumulator ^ currentValue,
      0
    );
  };
  // Convert a byte to a two-character hexadecimal string, padded with leading zero if necessary
  const toHexString = (byte) => {
    return byte.toString(16).toUpperCase().padStart(2, "0");
  };
  // Build command string for a given slot
  const buildCommandSend = (slot) => {
    // Convert slot number to a two-character hexadecimal string, padded with leading zero if necessary
    const formattedSlot = parseInt(slot)
      .toString(16)
      .toUpperCase()
      .padStart(2, "0");

    // Construct the hex string with the given slot
    const hexString = `FF01${formattedSlot}02000000`;

    // Convert the hex string to an array of bytes
    const bytes = hexStringToBytes(hexString);

    // Calculate the checksum using XOR
    const checksum = checksumXOR(bytes);

    // Return the complete command string with the checksum appended
    return `${hexString}${toHexString(checksum)}`;
  };

  const handleSendCmd = () => {
    const commandSend = `${serialPort}:${buildCommandSend(productInfo.slot)}`;
    console.log(commandSend);
    client.publish(pubTopicStorage, commandSend, { qos });
  };

  const getCurrentTime = () => {
    let currentDateTime = new Date();

    // Get the year, month, and day components
    let year = currentDateTime.getFullYear();
    // Months are zero-based, so we add 1 to get the correct month
    let month = currentDateTime.getMonth() + 1;
    let day = currentDateTime.getDate();

    // Pad single-digit months and days with a leading zero if needed
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Get the hour, minute, and second components
    let hour = currentDateTime.getHours();
    let minute = currentDateTime.getMinutes();
    let second = currentDateTime.getSeconds();

    // Pad single-digit hour, minute, and second with a leading zero if needed
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    let formattedTime = `${hour}:${minute}:${second}`;

    let time = `${year}-${month}-${day} ${formattedTime}`;

    return time;
  };

  const handleLogout = () => {
    navigateToPage("/scanqrcode");
    resetMachineStatus();
    handleRemoveToken();
    handleLogOutGTC();
  };

  const resetMachineStatus = async () => {
    console.log("RESETED");
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachineStatus/${machineId}`,
        {
          machineStatus: 0,
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  const handleRemoveToken = async () => {
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteToken`,
        {
          data: {
            token: token,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.error("Error when delete token: ", err);
    }
  };
  //transfer
  const handleTransfer = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/transfer-v2`, {
        token: token,
        value: 1,
        machineId: machineId,
        agencyId: agencyId,
        time: getCurrentTime(),
      });
      console.log(res.data.result.success);
      setCompletedStatus(res.data.result.success);
    } catch (error) {
      console.error(`Error when completed order ${error}`);
    }
  };
  //callback
  const handleGTCAPI = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/callback`, {
        token: token,
        balance: balance,
        machineId: machineId,
      });
      // setIsTokenValid(res.data.result);
      console.log(res);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  //logout
  const handleLogOutGTC = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/logout`, {
        token: token,
        machineId: machineId,
      });

      console.log(res);
    } catch (error) {
      console.error(`Error when getting machine info ${error}`);
    }
  };

  return (
    <div className="transcom__container">
      <div className="transcom__content">
        <div className="transcom__header">giao dịch thành công</div>
        <div className="transcom__result">
          <div className="transcom__icon_box success">
            <FaCheck className="transcom__icon" />
          </div>
          <div className="confirm__waiting">
            <div className="confirm__waiting_text">
              Cảm ơn bạn đã mua hàng
              <br />
              Chúc bạn có một ngày tốt lành !
            </div>
          </div>
          {completedStatus ? (
            <div className="transcom__btn_box">
              <div
                onClick={() => {
                  handleLogout();
                  handleTransfer();
                }}
                className="transcom__btn continue"
              >
                Hoàn tất
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="state">
        <div className="state__item done">
          <FaCheck className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item done">
          <FaCheck className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item done">
          <FaCheck className="state__icon" />
        </div>
      </div>
      <div className="count__down">
        Đăng xuất trong
        <CountDown
          pathPage={"/scanqrcode"}
          data={{
            token: token,
            value: 0,
            machineId: machineId,
            agencyId: agencyId,
          }}
        />
      </div>
      {isTokenValid ? (
        <></>
      ) : (
        <div className="valid">
          <div className="valid__content">
            <FaExclamation className="valid__icon" />
            <div className="valid__headline">
              Phiên làm việc đã hết hạn <br /> Vui lòng đăng nhập lại để thực
              hiện giao dịch mới
            </div>
            <div
              className="valid__btn"
              onClick={() => {
                navigateToPage("/scanqrcode");
                resetMachineStatus();
                handleRemoveToken();
              }}
            >
              đăng nhập
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
