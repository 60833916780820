import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../scss/Cashing.scss";
import { FaAngleLeft, FaExclamation } from "react-icons/fa";
import mqtt from "mqtt";
import axios from "axios";
import CountDown from "../CountDown";

export default function WithDraw() {
  const [withDrawValue, setWithDrawValue] = useState(0);
  const [err, setErr] = useState("");
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");
  const [returnedMoneyStorage, setReturnedMoneyStorage] = useState(0);
  const [isWithDraw, setIsWithDraw] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [moneyReturnTotal, setMoneyReturnTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [transactionId, setTransactionId] = useState(0);
  const [withDrawWait, setWithDrawWait] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(false);
  const [isWithDrawRemaining, setIsWithDrawRemaining] = useState(true);

  const bankPort = "/dev/ttyS3";

  const navigate = useNavigate();

  const { state } = useLocation();
  const machineId = state?.machineId;
  const agencyId = state?.agencyId;
  const token = state?.token;
  const balance = state?.balance;
  const machineInfo = state?.machineInfo;

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  // const DBurl = "https://vending-api.gtcmall.com";
  // const DBurl = "http://localhost:8080";
  const DBurl = "https://vending-machine-api.gtcmall.com";

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  //LOAD LOCALSTORAGE DATA PUB SUB
  useEffect(() => {
    // Load data from local storage on component mount
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopicStorage(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopicStorage(pubTopicStoredData);
    }
    const returnedMoneyStoredData = localStorage.getItem("t4rp5ihsc9");
    if (returnedMoneyStoredData) {
      setReturnedMoneyStorage(parseFloat(returnedMoneyStoredData));
    }
    handleGTCAPI();
    machineInfo.map((item) => setTransactionId(item.transactionId));
  }, []);

  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopicStorage, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const now = new Date();
        let hours = String(now.getHours()).padStart(2, "0");
        let minutes = String(now.getMinutes()).padStart(2, "0");
        let seconds = String(now.getSeconds()).padStart(2, "0");
        let current = `${hours}:${minutes}:${seconds}`;
        console.log(current);
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [subTopicStorage, pubTopicStorage]);

  useEffect(() => {
    if (client) {
      handleAccept();
    }
  }, [client]);

  useEffect(() => {
    if (response && response.substring(0, 17) === "/dev/ttyS3:010103") {
      setMoneyReturnTotal(parseInt(response.substring(21, 23), 16));
      setIsLoading(false);
    }
  }, [response]);

  useEffect(() => {
    if (withDrawWait) {
      setTimeout(() => {
        setIsShowBtn(true);
      }, 10000);
    }
  }, [withDrawWait]);

  //HANDLE FORMAT NUMBER
  const handelFormat = (number) => {
    return new Intl.NumberFormat().format(number);
  };
  //HANDLE CHECK WITHDRAW VALUE ONLY NUMBER
  const handleChangeWithDraw = (e) => {
    const { value } = e.target;
    if (value === "" || /^-?\d*\.?\d*$/.test(value)) {
      setWithDrawValue(value);
    }
  };
  // Convert a hexadecimal string to an array of bytes
  const hexStringToBytes = (hex) => {
    return hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16));
  };
  // Calculate checksum using XOR on an array of bytes
  const checksumXOR = (bytes) => {
    return bytes.reduce(
      (accumulator, currentValue) => accumulator ^ currentValue,
      0
    );
  };
  // Convert a byte to a two-character hexadecimal string, padded with leading zero if necessary
  const toHexString = (byte) => {
    return byte.toString(16).toUpperCase().padStart(2, "0");
  };
  // Build command string for withdraw -- DISABLE
  const buildCommandSendWithDraw = (number) => {
    // Convert slot number to a two-character hexadecimal string, padded with leading zero if necessary
    const cashAmount = (number * 25000) / (returnedMoneyStorage * 25000);
    const formattedSlot = parseInt(cashAmount)
      .toString(16)
      .toUpperCase()
      .padStart(2, "0");
    // Construct the hex string with the given slot
    const hexString = `030101001C${formattedSlot}`;

    // Convert the hex string to an array of bytes
    const bytes = hexStringToBytes(hexString);

    // Calculate the checksum using XOR
    const checksum = checksumXOR(bytes);

    // Return the complete command string with the checksum appended
    return `${hexString}${toHexString(checksum)}`;
  };
  //HANDLE WITHDRAW
  const handleSendCmdWithDraw = () => {
    const commandSend = `${bankPort}:${buildCommandSendWithDraw(
      withDrawValue
    )}`;
    console.log(commandSend);
    console.log(withDrawValue);
    client.publish(pubTopicStorage, commandSend, { qos });
  };
  //HANDLE ACCEPT MONEY
  const handleAccept = () => {
    console.log("accept");
    const command = `${bankPort}:0302010015FFFF15`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
    setTimeout(() => {
      handleReadCashAmount();
    }, 5000);
  };
  //CASH AMOUNT
  const handleReadCashAmount = () => {
    console.log("number of return money");
    const command = `${bankPort}:030001001B19`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
  };
  //withdraw
  const handleWithDraw = async () => {
    if (withDrawValue === "" || withDrawValue === 0) {
      setErr("Số điểm không được bỏ trống");
    } else if (withDrawValue < returnedMoneyStorage) {
      setErr(`Số điểm phải lớn hơn hoặc bằng ${returnedMoneyStorage}GT`);
    } else if (withDrawValue > returnedMoneyStorage * moneyReturnTotal) {
      setErr(
        `Số điểm vượt quá giới hạn cho phép ${
          returnedMoneyStorage * moneyReturnTotal
        }GT`
      );
    } else if (withDrawValue % returnedMoneyStorage !== 0) {
      setErr(`Số điểm phải là bội số của ${returnedMoneyStorage}`);
    } else if (balance - withDrawValue < 0) {
      setErr("Số điểm GT không đủ để rút.");
    } else {
      setIsWithDraw(true);
      try {
        const res = await axios.post(`${DBurl}/gtc/selling-machine/withdraw`, {
          token: token,
          agencyId: agencyId,
          machineId: machineId,
          amount: withDrawValue * 25000,
        });
        console.log(res.data);
        if (res.data.result.success) {
          handleSendCmdWithDraw();
          setWithDrawWait(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  //HANDLE RESET MACHINE STATUS
  const resetMachineStatus = async () => {
    console.log("RESETED");
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachineStatus/${machineId}`,
        {
          machineStatus: 0,
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };
  //HANDLE REMOVE TOKEN
  const handleRemoveToken = async () => {
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteToken`,
        {
          data: {
            token: token,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.error("Error when delete token: ", err);
    }
  };
  //transfer
  const handleTransfer = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/transfer-v2`, {
        token: token,
        value: 0,
        machineId: machineId,
        agencyId: agencyId,
        time: getCurrentTime(),
      });
      console.log(res.data.result.success);
      // setCompletedStatus(res.data.result.success);
    } catch (error) {
      navigateToPage("/trans-failed");
    }
  };
  //callback
  const handleGTCAPI = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/callback`, {
        token: token,
        balance: balance,
        machineId: machineId,
      });
      setIsTokenValid(res.data.result);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  //logout
  const handleLogOutGTC = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/logout`, {
        token: token,
        machineId: machineId,
      });

      console.log(res);
    } catch (error) {
      console.error(`Error when getting machine info ${error}`);
    }
  };
  const getCurrentTime = () => {
    let currentDateTime = new Date();

    // Get the year, month, and day components
    let year = currentDateTime.getFullYear();
    // Months are zero-based, so we add 1 to get the correct month
    let month = currentDateTime.getMonth() + 1;
    let day = currentDateTime.getDate();

    // Pad single-digit months and days with a leading zero if needed
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Get the hour, minute, and second components
    let hour = currentDateTime.getHours();
    let minute = currentDateTime.getMinutes();
    let second = currentDateTime.getSeconds();

    // Pad single-digit hour, minute, and second with a leading zero if needed
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    let formattedTime = `${hour}:${minute}:${second}`;

    let time = `${year}-${month}-${day} ${formattedTime}`;

    return time;
  };

  return (
    <div className="cashing__container">
      <div className="cashing__header">
        <div
          onClick={() =>
            navigateToPage(`/homepage`, {
              machineId: machineId,
              agencyId: agencyId,
              token: token,
              balance: balance,
            })
          }
          className="header__back able"
        >
          <FaAngleLeft className="header__back_icon" />
        </div>
        <div className="header__title">Hoàn tiền</div>
      </div>
      <div className="cashing__content">
        <div className="withdraw__content">
          <div className="withdraw__item summary">
            <div className="headline">Điểm GT</div>
            <div className="number">
              {handelFormat(balance)} <span className="currency">GT</span>
            </div>
          </div>
          <div className="withdraw__item value">
            <div className="headline">Nhập số điểm muốn rút</div>
            <div className="input__field">
              <input value={withDrawValue} onChange={handleChangeWithDraw} />
              <span className="currency">GT</span>
            </div>
          </div>
          <div className="withdraw__err">{err}</div>
          {isLoading ? (
            <div className="withdraw__loading">
              Đang kiểm tra hệ thống
              <br />
              Vui lòng chờ trong giây lát
              <div className="dots ">
                <div className="dot first"></div>
                <div className="dot second"></div>
                <div className="dot third"></div>
              </div>
            </div>
          ) : (
            <>
              {moneyReturnTotal === 0 ? (
                <div className="withdraw__empty">
                  Số tiền trong máy không đủ để thực hiện giao dịch hoàn tiền{" "}
                  <br /> Vui lòng liên hệ chủ máy để nạp thêm.
                </div>
              ) : (
                <div className="withdraw__btn" onClick={handleWithDraw}>
                  Rút ngay
                </div>
              )}
            </>
          )}
          {isWithDraw ? (
            <div className="cashing__withdraw_success">
              <div className="withdraw__process">
                <div className="headline">
                  Vui lòng kiểm tra số tiền trước khi đăng xuất khỏi hệ thống.
                </div>
                <div className="dots">
                  <div className="dot first"></div>
                  <div className="dot second"></div>
                  <div className="dot third"></div>
                </div>
                {isShowBtn ? (
                  <div
                    className="withdraw__success_btn"
                    onClick={() => {
                      navigateToPage("/scanqrcode");
                      resetMachineStatus();
                      handleRemoveToken();
                      handleLogOutGTC();
                    }}
                  >
                    Đăng xuất
                  </div>
                ) : (
                  <></>
                )}
                <div className="withdraw__logout">
                  Đăng xuất trong
                  <CountDown
                    pathPage={"/scanqrcode"}
                    data={{
                      token: token,
                      value: 0,
                      machineId: machineId,
                      agencyId: agencyId,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {isTokenValid ? (
        <></>
      ) : (
        <div className="valid">
          <div className="valid__content">
            <FaExclamation className="valid__icon" />
            <div className="valid__headline">
              Phiên làm việc đã hết hạn <br /> Vui lòng đăng nhập lại để thực
              hiện giao dịch mới
            </div>
            <div
              className="valid__btn"
              onClick={() => {
                navigateToPage("/scanqrcode");
                resetMachineStatus();
                handleRemoveToken();
              }}
            >
              đăng nhập
            </div>
          </div>
        </div>
      )}
      {isWithDrawRemaining && (
        <div className="valid">
          <div className="valid__content">
            <FaExclamation className="valid__icon" />
            <div className="valid__headline">
              Hạn mức hoàn tiền tối đa trong ngày của bạn là 20GT. <br /> Trong
              quá trình sử dụng nếu có vấn đề phát sinh xin vui lòng liên hệ chủ
              máy để hỗ trợ xử lý.
            </div>
            <div
              className="valid__btn"
              onClick={() => {
                setIsWithDrawRemaining(false);
              }}
            >
              tiếp tục thực hiện
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
