import React from "react";
import { useNavigate } from "react-router-dom";
import "../scss/Ads.scss";
import VideoPlayer from "./Video";
import adsVideo from "../images/ad4.mp4";
import Logo from "../images/logo.jpg";
import { FaHandPointUp } from "react-icons/fa";

function AdsPage(props) {
  const navigate = useNavigate();

  const navigateToPage = (pageUrl) => {
    navigate(pageUrl);
  };

  return (
    <div className="ads__container">
      <div
        onClick={() => navigateToPage("/scanqrcode")}
        className="ads__layer_click"
      >
        <img src={Logo} alt="Logo" className="ads__waiter" />
        <div className="ads__touch">
          <div className="ads__touch_circle"></div>
          <div className="ads__touch_circle_delay"></div>
          <FaHandPointUp className="ads__pointer" />
        </div>
        <div className="ads__headline">Chạm vào màn hình để bắt đầu</div>
      </div>
    </div>
  );
}

export default AdsPage;
