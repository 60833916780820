import React, { useEffect, useState } from "react";
import "../scss/Payment.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaList,
  FaMoneyBill,
  FaAngleLeft,
  FaCheck,
  FaExclamation,
} from "react-icons/fa";
import CountDown from "./CountDown";
import axios from "axios";

export default function PaymentPage() {
  const [isTokenValid, setIsTokenValid] = useState(true);

  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  const { state } = useLocation();
  // const transactionDetail = state?.transactionDetail;
  const productInfo = state?.productInfo;
  const machineId = state?.machineId;
  const agencyId = state?.agencyId;
  const token = state?.token;
  const balance = state?.balance;
  const loginInfo = state?.loginInfo;

  const imageURL = "https://gtcmartvn.com/";
  // const DBurl = "https://vending-api.gtcmall.com";
  // const DBurl = "http://localhost:8080";
  const DBurl = "https://vending-machine-api.gtcmall.com";

  useEffect(() => {
    handleGTCAPI();
  }, []);

  const getCurrentTime = () => {
    let currentDateTime = new Date();

    // Get the year, month, and day components
    let year = currentDateTime.getFullYear();
    // Months are zero-based, so we add 1 to get the correct month
    let month = currentDateTime.getMonth() + 1;
    let day = currentDateTime.getDate();

    // Pad single-digit months and days with a leading zero if needed
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Get the hour, minute, and second components
    let hour = currentDateTime.getHours();
    let minute = currentDateTime.getMinutes();
    let second = currentDateTime.getSeconds();

    // Pad single-digit hour, minute, and second with a leading zero if needed
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    let formattedTime = `${hour}:${minute}:${second}`;

    let time = `${year}-${month}-${day} ${formattedTime}`;

    return time;
  };

  const handelFormat = (number) => {
    return new Intl.NumberFormat().format(number);
  };
  //HANDLE RESET MACHINE STATUS
  const resetMachineStatus = async () => {
    console.log("RESETED");
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachineStatus/${machineId}`,
        {
          machineStatus: 0,
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };
  //HANDLE REMOVE TOKEN
  const handleRemoveToken = async () => {
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteToken`,
        {
          data: {
            token: token,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.error("Error when delete token: ", err);
    }
  };
  //transfer
  const handleTransfer = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/transfer-v2`, {
        token: token,
        value: 0,
        machineId: machineId,
        agencyId: agencyId,
        time: getCurrentTime(),
      });
      console.log(res.data.result.success);
      // setCompletedStatus(res.data.result.success);
    } catch (error) {
      navigateToPage("/trans-failed");
    }
  };
  //callback
  const handleGTCAPI = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/callback`, {
        token: token,
        balance: balance,
        machineId: machineId,
      });
      setIsTokenValid(res.data.result);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  //logout
  const handleLogOutGTC = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/logout`, {
        token: token,
        machineId: machineId,
      });

      console.log(res);
    } catch (error) {
      console.error(`Error when getting machine info ${error}`);
    }
  };

  return (
    <div className="payment__container">
      <div className="payment__header">
        <div
          onClick={() =>
            navigateToPage("/homepage", {
              balance: balance,
              machineId: machineId,
              agencyId: agencyId,
              token: token,
              loginInfo: loginInfo,
            })
          }
          className="header__back"
        >
          <FaAngleLeft className="header__back_icon" />
        </div>
        <div className="header__title">Thanh toán đơn hàng</div>
      </div>
      <div className="payment__content">
        <div className="payment__summary">
          <div className="payment__summary_content">
            <img
              // src={productInfo.thumbnailUrl}
              src={`${imageURL}${productInfo.cover_image_path}`}
              alt={productInfo.name}
              className="summary__image"
            />
            <div className="summary__info">
              <div className="summary__price">
                <div className="info__item">{productInfo.name}</div>
                <div className="info__item">
                  {productInfo.price}
                  <span className="currency">GT</span>
                </div>
              </div>
              <div className="summary__line"></div>
              <div className="summary__total">
                <div className="info__total">Tổng</div>
                <div className="info__total">
                  {productInfo.price}
                  <span className="currency">GT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="payment__method">
          {/* <div
            className="method__item"
            onClick={() =>
              navigateToPage("/payment-cash", {
                productInfo: productInfo,
                machineId: machineId,
                agencyId: agencyId,
                token: token,
                balance: balance,
              })
            }
          >
            <FaMoneyBill className="method__icon" />
            <div className="method__title cash">cash</div>
          </div> */}
          {balance - productInfo.price >= 0 ? (
            <div
              onClick={() =>
                navigateToPage("/payment-gtc", {
                  productInfo: productInfo,
                  machineId: machineId,
                  agencyId: agencyId,
                  token: token,
                  balance: balance,
                  loginInfo: loginInfo,
                })
              }
              className="method__item able"
            >
              <div className="method__coin">
                <div className="method__coin_balance">
                  {handelFormat(balance)}
                </div>
              </div>
              <div className="method__title gtc">gt</div>
            </div>
          ) : (
            <div className="method__item disable">
              <div className="disable__group">
                <div className="method__coin">
                  <div className="method__coin_balance">
                    {handelFormat(balance)}
                  </div>
                </div>
                <div className="method__title gtc">gt</div>
              </div>
              <div className="disable__text">
                Bạn không đủ điểm để thực hiện giao dịch này <br />
                Vui lòng nạp thêm tại màn hình chính để tiếp tục thực hiện giao
                dịch
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="state">
        <div className="state__item done">
          <FaCheck className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item active">
          <FaMoneyBill className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item ">
          <FaList className="state__icon" />
        </div>
      </div>
      <div
        onClick={() => {
          navigateToPage("/scanqrcode");
          resetMachineStatus();
          handleRemoveToken();
          handleLogOutGTC();
        }}
        className="logout"
      >
        <div>đăng xuất</div>
        <CountDown
          pathPage={"/scanqrcode"}
          data={{
            machineId: machineId,
            agencyId: agencyId,
            token: token,
            value: 0,
          }}
        />
      </div>
      {isTokenValid ? (
        <></>
      ) : (
        <div className="valid">
          <div className="valid__content">
            <FaExclamation className="valid__icon" />
            <div className="valid__headline">
              Phiên làm việc đã hết hạn <br /> Vui lòng đăng nhập lại để thực
              hiện giao dịch mới
            </div>
            <div
              className="valid__btn"
              onClick={() => {
                navigateToPage("/scanqrcode");
                resetMachineStatus();
                handleRemoveToken();
              }}
            >
              đăng nhập
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
