import axios from "axios";
import React, { useEffect, useState } from "react";

export default function LoginWithAPI() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [machineId, setMachineId] = useState("");
  const [machineStatus, setMachineStatus] = useState(0);
  const [token, setToken] = useState("");
  const [balance, setBalance] = useState(0);
  const [machineStatusValue, setMachineStatusValue] = useState(0);

  const DBurl = "https://vending-machine-api.gtcmall.com";

  useEffect(() => {
    const machineIdStoredData = localStorage.getItem("phyf26idk4");
    if (machineIdStoredData) {
      setMachineId(machineIdStoredData);
    }
  }, [machineId]);

  const getMachineStatus = async () => {
    console.log("GETTING STATUS");
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getMachineStatus`,
        {
          params: {
            machineId: machineId,
          },
        }
      );
      // console.log(`Machine status:`, res.data);
      setMachineStatusValue(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  const getToken = async () => {
    console.log("GETTING TOKEN");
    try {
      const res = await axios.get(`${DBurl}/vending-machine-api/getToken`, {
        params: {
          machineId: machineId,
        },
      });
      console.log(`Token:`, res.data);
      // setMachineStatusValue(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  const resetMachineStatus = async () => {
    console.log("RESETED");
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachineStatus/${machineId}`,
        {
          machineStatus: 0,
        }
      );
      console.log(`Machine status:`, res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  const removeToken = async () => {
    console.log("REMOVED");
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteToken`,
        {
          data: {
            token: token,
          },
        }``
      );
      console.log(`Token:`, res.data);
    } catch (err) {
      console.error("Error when delete token: ", err);
    }
  };

  // useEffect(() => {
  //   let elapsedTime = 0;
  //   const interval = setInterval(() => {
  //     getMachineStatus();
  //     getToken();
  //     // You can call your desired function here
  //     elapsedTime += 2000; // Increment elapsed time by 5 seconds
  //     if (elapsedTime >= 20000) {
  //       // Stop after 10 seconds
  //       resetMachineStatus();
  //       removeToken();
  //       clearInterval(interval);
  //     }
  //   }, 2000); // 5000 milliseconds = 5 seconds
  //   return () => clearInterval(interval); // Clean up interval on component unmount
  // }, []);

  // useEffect(() => {
  //   if (machineStatusValue && machineStatusValue[0].machineStatus === 1) {
  //     console.log("OKELA");
  //   }
  // }, [machineStatusValue]);

  const handleLogin = async () => {
    try {
      const res = await axios.post(
        `${DBurl}/vending-machine-api/editMachineStatus`,
        {
          machineId: machineId,
          machineStatus: machineStatus,
          token: token,
          balance: balance,
        }
      );
      console.log(res.data);
      // Check if the request was successful
      if (res.data) {
        console.log("Machine status updated successfully");
      } else {
        console.log("Error:", res.data.message);
      }
    } catch (err) {
      if (err.response) {
        console.log("Server Error:", err.response.data);
      } else if (err.request) {
        console.log("Network Error:", err.request);
      } else {
        console.log("Error:", err.message);
      }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 100 }}>
      <div style={{ fontSize: 100 }}>Login</div>
      <div style={{ display: "flex", flexDirection: "column", gap: 50 }}>
        <input
          placeholder="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ fontSize: 40 }}
        />
        <input
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ fontSize: 40 }}
        />
        <input
          placeholder="machineId"
          value={machineId}
          onChange={(e) => setMachineId(e.target.value)}
          style={{ fontSize: 40 }}
        />
        <input
          placeholder="token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          style={{ fontSize: 40 }}
        />
        <input
          placeholder="balance"
          value={balance}
          onChange={(e) => setBalance(e.target.value)}
          style={{ fontSize: 40 }}
        />
        <input
          placeholder="machineStatus"
          value={machineStatus}
          onChange={(e) => setMachineStatus(e.target.value)}
          style={{ fontSize: 40 }}
        />
      </div>
      <div style={{ fontSize: 30 }} onClick={handleLogin}>
        submit
      </div>
      <div style={{ fontSize: 30 }} onClick={removeToken}>
        remove token
      </div>
      <div style={{ fontSize: 30 }} onClick={getMachineStatus}>
        machine status
      </div>
      <div style={{ fontSize: 30 }} onClick={resetMachineStatus}>
        reset
      </div>
      <div style={{ fontSize: 30 }} onClick={getToken}>
        get token
      </div>
    </div>
  );
}
