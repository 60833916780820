import mqtt from "mqtt";
import React, { useEffect, useState } from "react";
import "../../scss/AdminMoneyConfig.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { Value } from "sass";

export default function MoneyConfig() {
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");
  const [returnedMoneyStorage, setReturnedMoneyStorage] = useState(0);
  const [moneyReturnTotal, setMoneyReturnTotal] = useState(0);
  const [selectedReturnedValue, setSelectedReturnedValue] = useState(0);
  const [returnedMoneyValue, setReturnedMoneyValue] = useState(0);
  const [isAlert, setIsAlert] = useState(false);
  const [isSetUp, setIsSetUp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useLocation();
  const userInfo = state?.userInfo;

  const navigate = useNavigate();
  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  const serialPort = "/dev/ttyS5";
  const bankPort = "/dev/ttyS3";

  const allowedValues = [
    { id: 1, gt: 0.04, value: 1 },
    { id: 2, gt: 0.08, value: 2 },
    { id: 3, gt: 0.2, value: 3 },
    { id: 4, gt: 0.4, value: 4 },
    { id: 5, gt: 0.8, value: 5 },
    { id: 6, gt: 2, value: 6 },
    { id: 7, gt: 4, value: 7 },
    { id: 8, gt: 8, value: 8 },
    { id: 9, gt: 20, value: 9 },
  ];
  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  //RUN ONCE WHEN COMPONENT MOUNTED
  useEffect(() => {
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopicStorage(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopicStorage(pubTopicStoredData);
    }
    const returnedMoneyStoredData = localStorage.getItem("t4rp5ihsc9");
    if (returnedMoneyStoredData) {
      setReturnedMoneyValue(parseFloat(returnedMoneyStoredData));
    }
  }, [isAlert]);

  //MQTT COMMUNICATION
  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopicStorage, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [pubTopicStorage, subTopicStorage]);

  // Get the number of cash inside returned box
  useEffect(() => {
    if (client) {
      handleAccept();
    }
  }, [client]);

  useEffect(() => {
    if (response && response.substring(0, 17) === "/dev/ttyS3:010103") {
      // setMoneyReturnTotal(parseInt(response.substring(21, 23)));
      setMoneyReturnTotal(parseInt(response.substring(21, 23), 16));
      setIsLoading(false);
    }
  }, [response]);

  // HANDLE FORMAT NUMBER
  const handleFormat = (number) => {
    // Handle edge cases where number is not a valid number
    if (isNaN(number) || number === "") {
      return number;
    }
    return new Intl.NumberFormat().format(number);
  };
  //CASH AMOUNT
  const handleReadCashAmount = () => {
    console.log("number of return money");
    const command = `${bankPort}:030001001B19`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
  };
  //SET RETURN
  const handleSetReturn = () => {
    console.log("set return money");
    const command = `${bankPort}:03010100170115`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
  };
  //ALL ACCEPT
  const handleAccept = () => {
    console.log("accept");
    const command = `${bankPort}:0302010015FFFF15`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
    setTimeout(() => {
      handleSetReturn();
      setTimeout(() => {
        handleReadCashAmount();
      }, 5000);
    }, 5000);
  };
  //COMMUNICATE WITH PORT
  const handleSendCmd = () => {
    setIsAlert(true);
    // Convert a hexadecimal string to an array of bytes
    const hexStringToBytes = (hex) => {
      return hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16));
    };
    // Calculate checksum using XOR on an array of bytes
    const checksumXOR = (bytes) => {
      return bytes.reduce(
        (accumulator, currentValue) => accumulator ^ currentValue,
        0
      );
    };
    // Convert a byte to a two-character hexadecimal string, padded with leading zero if necessary
    const toHexString = (byte) => {
      return byte.toString(16).toUpperCase().padStart(2, "0");
    };
    // Build command string for a given slot
    const buildCommandSend = (moneyReturnTotal) => {
      // Convert slot number to a two-character hexadecimal string, padded with leading zero if necessary
      const formattedSlot = parseInt(moneyReturnTotal)
        .toString(16)
        .toUpperCase()
        .padStart(2, "0");

      // Construct the hex string with the given slot
      // 030101001C011E
      const hexString = `030101001C${formattedSlot}`;

      // Convert the hex string to an array of bytes
      const bytes = hexStringToBytes(hexString);

      // Calculate the checksum using XOR
      const checksum = checksumXOR(bytes);

      // Return the complete command string with the checksum appended
      return `${hexString}${toHexString(checksum)}`;
    };
    const commandSend = `${bankPort}:${buildCommandSend(moneyReturnTotal)}`;
    console.log(commandSend);
    client.publish(pubTopicStorage, commandSend, { qos });
  };
  //HANDLE CHANGE RETURNED MONEY
  const handleChangeReturned = () => {
    localStorage.setItem("t4rp5ihsc9", selectedReturnedValue.gt);
    localStorage.setItem("8rl92hwckg", selectedReturnedValue.value);
    setIsAlert(false);
    setIsSetUp(false);
  };
  //REFESH PAGE
  const handleRefresh = () => {
    setIsAlert(false);
    setIsSetUp(false);
    handleReadCashAmount();
  };

  return (
    <div className="money__container">
      <div className="money__content">
        <div className="money__header">
          <div
            onClick={() =>
              navigateToPage("/admin-management", {
                userInfo: userInfo,
              })
            }
            className="header__back able"
          >
            <FaAngleLeft className="header__back_icon" />
          </div>
          <div className="header__title">Thiết lập hoàn tiền</div>
        </div>
        <div className="money__controller">
          {moneyReturnTotal > 0 ? (
            <div className="controller__content withdraw">
              <div className="withdraw__headline">
                Trong ngăn rút tiền hiện đang còn
                <div className="withdraw__unit">
                  <div className="title">Số tờ</div>
                  <div className="number">{moneyReturnTotal}</div>
                </div>
                <div className="withdraw__unit">
                  <div className="title">Mệnh giá</div>
                  <div className="number">
                    {" "}
                    {handleFormat(returnedMoneyValue * 25000)} VND
                  </div>
                </div>
              </div>
              <div className="withdraw__headline">
                Vui lòng rút hết tiền trong ngăn trước khi thiết lập mệnh giá
                mới
              </div>
              <div className="withdraw__btn" onClick={handleSendCmd}>
                Rút tất cả
              </div>
            </div>
          ) : (
            <div className="controller__content setup">
              <div className="setup__previous">
                <div className="title">Mệnh giá cũ</div>
                <div className="number">
                  <div className="gt">
                    {returnedMoneyValue} <span className="currency">GT</span>
                  </div>
                  <div className="vnd">
                    ({handleFormat(returnedMoneyValue * 25000)} VND)
                  </div>
                </div>
              </div>
              <div className="setup__headline">Chọn mệnh giá mới</div>
              <div className="setup__current">
                {allowedValues.map((item, index) => (
                  <div
                    className="item"
                    key={index}
                    style={{
                      color: returnedMoneyValue === item.gt ? "grey" : "#000",
                      backgroundColor:
                        returnedMoneyValue === item.gt ? "#153448" : "",
                    }}
                    onClick={() => {
                      setSelectedReturnedValue(item);
                      setIsAlert(true);
                      setIsSetUp(true);
                    }}
                  >
                    <div className="item__currency gt">
                      {item.gt}
                      <span className="currency">GT</span>
                    </div>
                    <div className="item__currency vnd">
                      ({handleFormat(item.gt * 25000)} VND)
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {isAlert && (
        <div className="alert">
          <div className="alert__content">
            {isSetUp ? (
              <div className="alert__headline">
                Bạn muốn cập nhật lại mệnh giá hoàn tiền <br />
                {selectedReturnedValue.gt} <span className="currency">GT</span>{" "}
                ({handleFormat(selectedReturnedValue.gt * 25000)} VND)
              </div>
            ) : (
              <div className="alert__headline">
                Vui lòng kiểm tra đủ số tiền trước khi rời máy
              </div>
            )}
            {isSetUp ? (
              <div className="alert__confirm">
                Nếu bạn chắc chắn những thay đổi trên, bấm XÁC NHẬN để cập nhật
                lại hệ thống.
              </div>
            ) : (
              <div className="alert__confirm">
                Khi xác nhận đã đủ tiền, bấm XÁC NHẬN để cập nhật lại hệ thống.
              </div>
            )}
            {isSetUp ? (
              <div className="alert__btn">
                <div
                  className="btn__item cancel"
                  onClick={() => {
                    setIsAlert(false);
                  }}
                >
                  HUỶ THAY ĐỔI
                </div>
                <div
                  className="btn__item confirm"
                  onClick={handleChangeReturned}
                >
                  XÁC NHẬN
                </div>
              </div>
            ) : (
              <div className="alert__btn">
                <div className="btn__item confirm" onClick={handleRefresh}>
                  XÁC NHẬN
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="loading">
          <div className="loading__content">
            <div className="loading__headline">
              Đang kiểm tra hệ thống
              <br />
              Vui lòng chờ trong giây lát
              <div className="dots ">
                <div className="dot first"></div>
                <div className="dot second"></div>
                <div className="dot third"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
