// src/App.js
import React, { useState } from "react";
import CryptoJS from "crypto-js";

const GetCrypto = () => {
  const [agencyValue, setAgencyValue] = useState(0);
  const [machineValue, setMachineValue] = useState("");
  const [encryptedData, setEncryptedData] = useState("");
  const [decryptedData, setDecryptedData] = useState("");
  const [decryptionInput, setDecryptionInput] = useState("");

  const secretKey = process.env.REACT_APP_SECRET_KEY; // Replace with your actual secret key

  const encryptData = () => {
    try {
      const inputText = JSON.stringify({
        agencyId: parseInt(agencyValue),
        machineId: machineValue,
      }); // Convert object to string
      const ciphertext = CryptoJS.AES.encrypt(inputText, secretKey).toString();
      setEncryptedData(ciphertext);
    } catch (error) {
      console.error("Encryption error:", error);
    }
  };

  // Function to decrypt data
  const decryptData = () => {
    try {
      const bytes = CryptoJS.AES.decrypt(decryptionInput, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setDecryptedData(decryptedData);
    } catch (error) {
      console.error("Decryption error:", error);
    }
  };

  return (
    <div>
      <h1>Encrypt and Decrypt with CryptoJS</h1>
      <div>
        <h2>Encrypt Data</h2>
        <input
          type="number"
          value={agencyValue}
          onChange={(e) => setAgencyValue(e.target.value)}
          placeholder="Enter agency ID"
        />
        <input
          type="text"
          value={machineValue}
          onChange={(e) => setMachineValue(e.target.value)}
          placeholder="Enter machine ID"
        />
        <button onClick={encryptData}>Encrypt</button>
        <p>Encrypted Data: {encryptedData}</p>
      </div>
      <div>
        <h2>Decrypt Data</h2>
        <input
          type="text"
          value={decryptionInput}
          onChange={(e) => setDecryptionInput(e.target.value)}
          placeholder="Enter encrypted string"
        />
        <button onClick={decryptData}>Decrypt</button>
        <p>Decrypted Data: {decryptedData && JSON.stringify(decryptedData)}</p>
      </div>
    </div>
  );
};

export default GetCrypto;
