import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCheck, FaExclamation, FaTimes } from "react-icons/fa";
import "../scss/TransResult.scss";
import CountDown from "./CountDown";
import axios from "axios";

export default function TransFail() {
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");
  const [phone, setPhone] = useState("");

  const { state } = useLocation();
  const token = state?.token;
  const machineId = state?.machineId;

  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  const DBurl = "https://vending-machine-api.gtcmall.com";
  // const DBurl = "https://vending-api.gtcmall.com";

  useEffect(() => {
    handleGetPhone();
  }, []);

  const resetMachineStatus = async () => {
    console.log("RESETED");
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachineStatus/${machineId}`,
        {
          machineStatus: 0,
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  const handleRemoveToken = async () => {
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteToken`,
        {
          data: {
            token: token,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.error("Error when delete token: ", err);
    }
  };

  const handleGetPhone = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getPhoneNumber`
      );
      if (res.data.success) {
        res.data.result.map((item) => {
          setPhone(item.number);
        });
      }
    } catch (err) {
      console.error("Failed to retrieve phone number", err);
    }
  };

  const handleFormatPhoneNumber = (number) => {
    let formattedNumber = number.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    return formattedNumber;
  };

  return (
    <div className="transcom__container">
      <div className="transcom__content">
        <div className="transcom__header">Phiên đăng nhập đã hết hạn</div>
        <div className="transcom__result">
          <div className="transcom__icon_box error">
            <FaExclamation className="transcom__icon" />
          </div>
          <div className="confirm__waiting">
            <div className="confirm__waiting_text">
              Phiên giao dịch hiện tại đã hết hạn. <br />
              Vui lòng đăng nhập để thực hiện giao dịch mới.
              <br />
              Nếu có bất kỳ thắc mắc xin liên hệ
              <br />
              {handleFormatPhoneNumber(phone)}
            </div>
          </div>
        </div>
      </div>
      <div className="state">
        <div className="state__item done">
          <FaCheck className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item done">
          <FaCheck className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item error">
          <FaTimes className="state__icon" />
        </div>
      </div>
      <div
        className="fail__count"
        onClick={() => {
          navigateToPage("/scanqrcode");
          resetMachineStatus();
          handleRemoveToken();
        }}
      >
        Trở về màn hình đăng nhập <CountDown pathPage={"/scanqrcode"} />
      </div>
    </div>
  );
}
