import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  FaList,
  FaMoneyBill,
  FaAngleLeft,
  FaCheck,
  FaExclamation,
} from "react-icons/fa";
import "../../scss/PaymentMethod.scss";
import { useNavigate } from "react-router-dom";
import CountDown from "../CountDown";
import axios from "axios";

export default function GTCPage() {
  const [transStatus, setTransStatus] = useState(null);
  const [createTransStatus, setCreateTransStatus] = useState(null);
  const [transInfo, setTransInfo] = useState({});
  const [createOrder, setCreateOrder] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [agencyInfo, setAgencyInfo] = useState("");

  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  const { state } = useLocation();
  const productInfo = state?.productInfo;
  const machineId = state?.machineId;
  const agencyId = state?.agencyId;
  const token = state?.token;
  const balance = state?.balance;
  const loginInfo = state?.loginInfo;

  const imageURL = "https://gtcmartvn.com/";
  // const DBurl = "https://vending-api.gtcmall.com";
  // const DBurl = "http://localhost:8080";
  const DBurl = "https://vending-machine-api.gtcmall.com";

  const getCurrentTime = () => {
    let currentDateTime = new Date();

    // Get the year, month, and day components
    let year = currentDateTime.getFullYear();
    // Months are zero-based, so we add 1 to get the correct month
    let month = currentDateTime.getMonth() + 1;
    let day = currentDateTime.getDate();

    // Pad single-digit months and days with a leading zero if needed
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Get the hour, minute, and second components
    let hour = currentDateTime.getHours();
    let minute = currentDateTime.getMinutes();
    let second = currentDateTime.getSeconds();

    // Pad single-digit hour, minute, and second with a leading zero if needed
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    let formattedTime = `${hour}:${minute}:${second}`;

    let time = `${year}-${month}-${day} ${formattedTime}`;

    return time;
  };

  useEffect(() => {
    handleGTCAPI();
    getAgencyById();
  }, []);
  //HANDLE CREATE TRANSACTION
  useEffect(() => {
    if (createOrder) {
      handlePurchase();
    }
  }, [createOrder]);

  //HANDLE COMPLETE TRANSACTION
  useEffect(() => {
    if (createTransStatus) {
      handleCheckOut();
    }
  }, [createTransStatus]);

  useEffect(() => {
    if (transStatus) {
      handleCreateBill();
      setTimeout(() => {
        navigateToPage("/trans-completed", {
          agencyId: agencyId,
          machineId: machineId,
          value: 1,
          token: token,
          balance: transInfo.balance,
          productInfo: productInfo,
        });
      }, 10000);
    }
  }, [transStatus]);

  //HADLE CREATE BILL
  const handleCreateBill = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtmall/selling-machine/postBill`, {
        agencyId: agencyId,
        agencyEmail: agencyInfo,
        customerId: loginInfo[0].customerId,
        customerEmail: loginInfo[0].userEmail,
        firstName: loginInfo[0].firstName,
        lastName: loginInfo[0].lastName,
        phoneNumber: loginInfo[0].userPhone,
        proId: productInfo.id,
        prodQuantity: 1,
      });
      console.log(res.data);
    } catch (error) {
      console.error(`Error when creating bill: ${error}`);
    }
  };

  const handelFormat = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  //GET AGENCY EMAIL BY MACHINEID
  const getAgencyById = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getAgencyById`,
        {
          params: {
            machineId: machineId,
          },
        }
      );
      setAgencyInfo(res.data[0].userName);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  //GTC PURCHASE V2
  const handlePurchase = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/purchase-v2`, {
        token: token,
        agencyId: agencyId,
        machineId: machineId,
        slot: 0,
        value: productInfo.price,
        ogprice: productInfo.original_product_price,
      });
      setCreateTransStatus(res.data.result.success);
      setTransInfo(res.data.result.result.purchaseRequestId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //GTC RESULT
  const handleCheckOut = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/result`, {
        purchaseRequestId: transInfo,
        machineId: machineId,
        transaction: 1,
        slot: productInfo.slot,
        value: productInfo.price,
        paymentMethod: 0,
        status: 1,
        totalSale: 1,
        totalMoneySale: productInfo.price,
        time: getCurrentTime(),
      });
      console.log(res.data.result.success);
      setTransStatus(res.data.result.success);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const resetMachineStatus = async () => {
    console.log("RESETED");
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachineStatus/${machineId}`,
        {
          machineStatus: 0,
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  const handleRemoveToken = async () => {
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteToken`,
        {
          data: {
            token: token,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.error("Error when delete token: ", err);
    }
  };

  //transfer
  const handleTransfer = async () => {
    console.log(agencyId);
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/transfer-v2`, {
        token: token,
        value: 0,
        machineId: machineId,
        agencyId: agencyId,
        time: getCurrentTime(),
      });
      console.log(res.data.result.success);
      // setCompletedStatus(res.data.result.success);
    } catch (error) {
      navigateToPage("/trans-failed");
    }
  };
  //callback
  const handleGTCAPI = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/callback`, {
        token: token,
        balance: balance,
        machineId: machineId,
      });
      setIsTokenValid(res.data.result);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  //logout
  const handleLogOutGTC = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/logout`, {
        token: token,
        machineId: machineId,
      });

      console.log(res);
    } catch (error) {
      console.error(`Error when getting machine info ${error}`);
    }
  };

  return (
    <div className="method__container">
      <div className="method__header">
        <div
          onClick={() =>
            navigateToPage(`/detail/:${productInfo.id}`, {
              productInfo: productInfo,
              machineId: machineId,
              agencyId: agencyId,
              token: token,
              balance: balance,
              loginInfo: loginInfo,
            })
          }
          className="header__back"
        >
          <FaAngleLeft className="header__back_icon" />
        </div>
        <div className="header__title">Thanh toán điểm GT</div>
      </div>
      <div className="method__content">
        <div className="method__summary">
          <div className="method__summary_content">
            <img
              // src={productInfo.thumbnailUrl}
              src={`${imageURL}${productInfo.cover_image_path}`}
              alt={productInfo.name}
              className="summary__image"
            />
            <div className="summary__info">
              <div className="summary__price">
                <div className="info__item">{productInfo.name}</div>
                <div className="info__item">
                  {productInfo.price}
                  <span className="currency">GT</span>
                </div>
              </div>
              <div className="summary__line"></div>
              <div className="summary__total">
                <div className="info__total">Tổng</div>
                <div className="info__total">
                  {productInfo.price}
                  <span className="currency">GT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="method__transaction">
          <div className="gtc__content">
            <div className="gtc__item">
              <div className="gtc__headline">Điểm GT</div>
              <div className="gtc__coin">
                <div className="gtc__coin_number">
                  {handelFormat(balance)}
                  <span className="currency">GT</span>
                </div>
              </div>
            </div>
            <div className="gtc__line"></div>
            <div className="gtc__item">
              <div className="gtc__headline">Giá trị sản phẩm</div>
              <div className="gtc__coin">
                <div className="gtc__coin_number">
                  {productInfo.price}
                  <span className="currency">GT</span>
                </div>
              </div>
            </div>
            <div className="gtc__line"></div>
            <div className="gtc__item">
              <div className="gtc__headline">Còn lại</div>
              <div className="gtc__coin">
                <div
                  className={
                    balance - productInfo.price >= 0
                      ? "gtc__coin_number"
                      : "gtc__coin_number error"
                  }
                >
                  {handelFormat(balance - productInfo.price)}
                  <span className="currency">GT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setCreateOrder(true);
            }}
            className="gtc__enough"
          >
            Tiếp tục
          </div>
        </div>
      </div>
      <div className="method__waiting">
        <div className="method__waiting_text">Đợi giao dịch được hoàn tất</div>
        <div
          className="count__down"
          onClick={() => {
            resetMachineStatus();
            handleRemoveToken();
            navigateToPage("/scanqrcode");
            handleLogOutGTC();
          }}
        >
          <CountDown
            pathPage={"/scanqrcode"}
            data={{
              machineId: machineId,
              agencyId: agencyId,
              token: token,
              value: 0,
            }}
          />
        </div>
      </div>
      <div className="state">
        <div className="state__item done">
          <FaCheck className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item active">
          <FaMoneyBill className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item ">
          <FaList className="state__icon" />
        </div>
      </div>
      {createOrder ? (
        <div className="loading__container">
          <div className="loading__text">
            Giao dịch đang được thực hiện
            <br />
            Vui lòng đợi trong giây lát
          </div>
          <div className="dots">
            <div className="dot first"></div>
            <div className="dot second"></div>
            <div className="dot third"></div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isTokenValid ? (
        <></>
      ) : (
        <div className="valid">
          <div className="valid__content">
            <FaExclamation className="valid__icon" />
            <div className="valid__headline">
              Phiên làm việc đã hết hạn <br /> Vui lòng đăng nhập lại để thực
              hiện giao dịch mới
            </div>
            <div
              className="valid__btn"
              onClick={() => {
                navigateToPage("/scanqrcode");
                resetMachineStatus();
                handleRemoveToken();
              }}
            >
              đăng nhập
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
