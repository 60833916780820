import React, { useEffect, useState } from "react";
import QRGenerate from "./QRGenerate";
import "../scss/QRCode.scss";
import { FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CountDown from "./CountDown";
import CryptoJS from "crypto-js";
import axios from "axios";
import mqtt from "mqtt";

import Logo from "../images/logo.jpg";

function QRCode(props) {
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [token, setToken] = useState(null);
  const [balance, setBalance] = useState(null);
  const [machineId, setMachineId] = useState(null);
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");
  const [agencyId, setAgencyId] = useState(null);
  const [loginStatus, setLoginStatus] = useState(null);
  const [unlockStatus, setUnlockStatus] = useState(null);
  const [agencyValue, setAgencyValue] = useState(0);
  const [machineValue, setMachineValue] = useState("");
  const [encryptedText, setEncryptedText] = useState("");
  const [qrcodeValue, setQRCODEValue] = useState("");
  const [error, setError] = useState("");
  const [machineStatusValue, setMachineStatusValue] = useState([]);
  const [tokenValue, setTokenValue] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // const DBurl = "https://vending-api.gtcmall.com";
  const DBurl = "https://vending-machine-api.gtcmall.com";
  // const DBurl = "http://localhost:8080";

  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  //HANDLE GET LOGIN
  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://gpc-api-demo-yy.3true.com/mac-api/login/selling-machine", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: "accurate69@yogirt.com",
        password: "aA@123456",
        agencyId: agencyValue,
        machineId: machineValue,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle response data as needed
        console.log(data);
        setLoginStatus(data.success);
        setToken(data.result.token);
        setBalance(data.result.balance);
        setMachineId(data.result.machineId);
        setAgencyId(data.result.agencyId);
      })
      .catch((error) => {
        // Handle error
        console.error("There was an error!", error);
      });
  };

  const bankPort = "/dev/ttyS3";

  useEffect(() => {
    // Load data from local storage on component mount
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopicStorage(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopicStorage(pubTopicStoredData);
    }
  }, []);

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopicStorage, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [pubTopicStorage, subTopicStorage]);

  useEffect(() => {
    if (loginStatus) {
      navigateToPage("/homepage", {
        balance: parseFloat(balance) / 25000,
        machineId: machineValue,
        agencyId: agencyValue,
        token: token,
      });
    }
  }, [loginStatus]);

  //GET MACHINESTATUS BASED ON MACHINEID
  const getMachineStatus = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getMachineStatus`,
        {
          params: {
            machineId: machineValue,
          },
        }
      );
      setMachineStatusValue(res.data);
      console.log(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  const getToken = async () => {
    console.log("GETTING TOKEN");
    try {
      const res = await axios.get(`${DBurl}/vending-machine-api/getToken`, {
        params: {
          machineId: machineValue,
        },
      });
      setTokenValue(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  //HANDLE LOAD LOCALSTORAGE AND CHECKING MACHINE STATUS
  useEffect(() => {
    let elapsedTime = 0;
    const interval = setInterval(() => {
      getMachineStatus();
      getToken();
      // You can call your desired function here
      elapsedTime += 2000;
      if (elapsedTime >= 30000) {
        // Stop after 10 seconds
        // resetMachineStatus();
        // removeToken();
        clearInterval(interval);
      }
    }, 2000);
    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [tokenValue]);

  //HANDLE GET UNLOCK STATUS
  useEffect(() => {
    if (token && isOpen) {
      handleGTCAPI();
    }
  }, [token, isOpen]);

  //HANDLE WHEN UNLOCK STATUS === TRUE
  useEffect(() => {
    if (unlockStatus) {
      navigateToPage("/homepage", {
        balance: parseFloat(balance) / 25000,
        machineId: machineValue,
        agencyId: agencyValue,
        token: token,
        loginInfo: tokenValue,
      });
    }
  }, [unlockStatus, balance, token]);

  useEffect(() => {
    if (client !== null) {
      handleReset();
    }
  }, [client]);

  //LOADING QRCODE WITH ENCRYPTED AGENCYDI AND MACHINEID
  useEffect(() => {
    // Load data from local storage on component mount
    const agencyIdStoredData = localStorage.getItem("61w2ujrf3t");
    const machineIdStoredData = localStorage.getItem("phyf26idk4");
    if (agencyIdStoredData && machineIdStoredData) {
      const secretKey = "8e9d9cf2-02f7-4487-8e56-617b1200ac74"; //prod
      // const secretKey = "d89ec9d9-708a-409a-9d70-7fabaaaffecd"; //dev
      try {
        const inputText = JSON.stringify({
          agencyId: parseInt(agencyIdStoredData),
          machineId: machineIdStoredData,
        }); // Convert object to string
        const encrypted = CryptoJS.AES.encrypt(inputText, secretKey).toString();
        setQRCODEValue(
          `https://goldtime5star.com/external-payment-v2/${encrypted}`
        ); //product
        // setQRCODEValue(
        //   `https://gpc-911ba739ee.fizzgyp.fun/external-payment-v2/${encrypted}`
        // ); //dev
        setError("");
      } catch (error) {
        setError("Encryption failed. Please try again.");
      }
      setMachineValue(machineIdStoredData);
      setAgencyValue(agencyIdStoredData);
    }
  }, [agencyValue, machineValue]);

  // OPEN MACHINE BASED ON MACHINE STATUS
  useEffect(() => {
    if (machineStatusValue) {
      machineStatusValue.map((item) => {
        if (item.machineStatus === 1) {
          setIsOpen(true);
          console.log("ONLINE");
        } else {
          console.log("OFFLINE");
        }
      });
      tokenValue.map((item) => {
        setBalance(item.balance);
        setToken(item.token);
      });
    }
  }, [machineStatusValue]);

  const handleReset = () => {
    console.log("reset");
    const command = `${bankPort}:030001000A08`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
  };

  //callback
  const handleGTCAPI = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/callback`, {
        token: token,
        balance: balance,
        machineId: machineValue,
      });
      setUnlockStatus(res.data);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const qrCodeSize = 280;

  return (
    <div className="qr__container">
      <div className="qr__content">
        <div className="qr__logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="qr__box">
          <QRGenerate value={qrcodeValue} size={qrCodeSize} />
        </div>
        <p onClick={handleSubmit} className="qr__text">
          Quét mã QR để đăng nhập
        </p>
      </div>
      <div
        onClick={() => navigateToPage("/admin-manage-login")}
        className="qr__admin"
      >
        <FaPen className="admin__icon" />
      </div>
      <div onClick={() => navigateToPage("/")} className="countdown">
        <CountDown pathPage={"/"} />
      </div>
      <div className="qr__underline"></div>
    </div>
  );
}

export default QRCode;
