import axios from "axios";
import React from "react";

export default function Test() {
  const DBurl = "http://localhost:8080";

  //HADLE CREATE BILL
  const handleCreateBill = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtmall/selling-machine/postBill`, {
        agencyId: 39,
        agencyEmail: "owner@gmail.com",
        customerId: 9828,
        userEmail: "user001@gmail.com",
        firstName: "QQ",
        lastName: "",
        userPhone: "012301023",
        productList: [
          {
            id: 480,
            quantity: 1,
          },
        ],
      });
      console.log(res.data);
    } catch (error) {
      console.error(`Error when creating bill: ${error}`);
    }
  };

  return (
    <div>
      <div onClick={handleCreateBill}>handleLogOut</div>
    </div>
  );
}
