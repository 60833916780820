import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../scss/CountDown.scss";
import axios from "axios";

const CountDown = ({ pathPage, data }) => {
  const [machineValue, setMachineValue] = useState("");
  const [agencyValue, setAgencyValue] = useState(0);

  const [timeLeft, setTimeLeft] = useState(300); //seconds
  const [timeUp, setTimeUp] = useState(false);

  // Format the remaining time into minutes and seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  // const pathPage = {};
  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  const DBurl = "https://vending-machine-api.gtcmall.com";
  // const DBurl = "https://vending-api.gtcmall.com";
  // const DBurl = "http://localhost:8080";

  const getCurrentTime = () => {
    let currentDateTime = new Date();

    // Get the year, month, and day components
    let year = currentDateTime.getFullYear();
    // Months are zero-based, so we add 1 to get the correct month
    let month = currentDateTime.getMonth() + 1;
    let day = currentDateTime.getDate();

    // Pad single-digit months and days with a leading zero if needed
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Get the hour, minute, and second components
    let hour = currentDateTime.getHours();
    let minute = currentDateTime.getMinutes();
    let second = currentDateTime.getSeconds();

    // Pad single-digit hour, minute, and second with a leading zero if needed
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    let formattedTime = `${hour}:${minute}:${second}`;

    let time = `${year}-${month}-${day} ${formattedTime}`;

    return time;
  };

  useEffect(() => {
    // Load data from local storage on component mount
    const agencyIdStoredData = localStorage.getItem("61w2ujrf3t");
    if (agencyIdStoredData) {
      setAgencyValue(agencyIdStoredData);
    }
    const machineIdStoredData = localStorage.getItem("phyf26idk4");
    if (machineIdStoredData) {
      setMachineValue(machineIdStoredData);
    }
  }, [agencyValue, machineValue]);

  const resetMachineStatus = async () => {
    console.log("RESETED");
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachineStatus/${machineValue}`,
        {
          machineStatus: 0,
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };

  const handleRemoveToken = async () => {
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteToken`,
        {
          data: {
            token: data.token,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.error("Error when delete token: ", err);
    }
  };

  useEffect(() => {
    if (timeUp && data !== undefined) {
      console.log(`Case 01`);
      navigateToPage(pathPage);
      resetMachineStatus();
      handleRemoveToken();
      // handleTransfer(data.value);
      handleLogOutGTC();
    } else if (timeUp) {
      console.log(`Case 01`);
      navigateToPage(pathPage);
    }
  }, [timeUp]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft === 0) {
          clearInterval(intervalId);
          setTimeUp(true);
        }
        return prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []); // Runs only once after the component mounts

  //transfer
  const handleTransfer = async (value) => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/transfer-v2`, {
        token: data.token,
        value: value,
        machineId: data.machineId,
        agencyId: data.agencyId,
        time: getCurrentTime(),
      });
      console.log(res.data.result.success);
    } catch (error) {
      // navigateToPage("/trans-failed", {
      //   token: data.token,
      //   machineId: data.machineId,
      // });
      console.error(`Error when log out ${error}`);
    }
  };
  //logout
  const handleLogOutGTC = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/logout`, {
        token: data.token,
        machineId: data.machineId,
      });

      console.log(res);
    } catch (error) {
      console.error(`Error when getting machine info ${error}`);
    }
  };

  return (
    <div className="count__container">
      <div className="count__text">
        ({minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds})
      </div>
    </div>
  );
};

export default CountDown;
