import React, { useState, useEffect } from "react";
import mqtt from "mqtt";
// import { useTranslation } from "react-i18next";

export default function CashController() {
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [rollingValue, setRollingValue] = useState("");
  const [pollValue, setPollValue] = useState("");
  const [stack, setStack] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const [startInterval, setStartInterval] = useState(false);
  const [numberA, setNumerA] = useState(0);
  const [b, setB] = useState(10); // Use state for b
  const [moneyReturnTotal, setMoneyReturnTotal] = useState(0);
  const [value, setValue] = useState("");
  const [valueStatus, setValueStatus] = useState("");

  const [total, setTotal] = useState(0);

  const allowedValues = [
    1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000,
  ];

  const serialPort = "/dev/ttyS5";
  const bankPort = "/dev/ttyS3";

  // const { t } = useTranslation();

  const pollList = [
    {
      id: "00",
      value: 0,
    },
    {
      id: "01",
      value: 1000,
    },
    {
      id: "02",
      value: 2000,
    },
    {
      id: "03",
      value: 5000,
    },
    {
      id: "04",
      value: 10000,
    },
    {
      id: "05",
      value: 20000,
    },
    {
      id: "06",
      value: 50000,
    },
    {
      id: "07",
      value: 100000,
    },
    {
      id: "08",
      value: 200000,
    },
    {
      id: "09",
      value: 500000,
    },
  ];

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe("SERVER0012", { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const now = new Date();
        let hours = String(now.getHours()).padStart(2, "0");
        let minutes = String(now.getMinutes()).padStart(2, "0");
        let seconds = String(now.getSeconds()).padStart(2, "0");
        let current = `${hours}:${minutes}:${seconds}`;
        console.log(current);
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        setNumerA(1);
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, []);

  const handleFindMatchingPoll = (poll) => {
    const result = pollList.find((item) => item.id === poll);
    if (result) {
      setTotalValue((prevTotal) => prevTotal + result.value);
    } else {
      console.log(`No matching value found for id ${poll}`);
    }
  };

  useEffect(() => {
    let intervalId;
    if (startInterval) {
      intervalId = handleReadMoneyValue();
    }
    // Cleanup interval on component unmount or if startInterval changes
    return () => clearInterval(intervalId);
  }, [startInterval, numberA, b]);

  useEffect(() => {
    if (response === "/dev/ttyS3:010003000103") {
      handleFindMatchingPoll(pollValue);
    }
  }, [response, pollValue]);

  useEffect(() => {
    if (response && response.substring(0, 17) === "/dev/ttyS3:010103") {
      setTotal(parseInt(response.substring(21, 23), 16));
    }
  }, [response]);

  const handleReadMoneyValue = () => {
    return setInterval(async () => {
      console.log(`chữ bê: ${b}`);
      // await handlePoll();
      if (b === 15) {
        await handlePoll();
        setB(0);
      }
      if (numberA === 1) {
        console.log(`ghe z sao ${numberA}`);
        if (response !== null && response.length === 49) {
          console.log("ua bro ??");
          const moneyValue = response.substring(25, 27);
          const moneyStatus = response.substring(23, 25);
          if (moneyStatus === "00" && moneyValue !== "00") {
            console.log("wtf bro ?");
            handleRollIn();
            setPollValue(moneyValue);
          }
        }
        setNumerA(0);
        setB(12);
      } else {
        setB((prevB) => prevB + 1);
      }
    }, 1000);
  };

  const handleReset = () => {
    console.log("reset");
    const command = `${bankPort}:030001000A08`;
    console.log(command);
    client.publish("DEVICE0012", command, { qos });
  };
  const handleSetReturn = () => {
    console.log("set return money");
    const command = `${bankPort}:03010100170115`;
    console.log(command);
    client.publish("DEVICE0012", command, { qos });
  };
  const handleAccept = () => {
    console.log("accept");
    const command = `${bankPort}:0302010015FFFF15`;
    console.log(command);
    client.publish("DEVICE0012", command, { qos });
    // setTimeout(() => {
    //   setStartInterval(true);
    //   setNumerA(0);
    // }, 5000);
  };
  const handlePoll = () => {
    console.log("poll");
    const now = new Date();
    let hours = String(now.getHours()).padStart(2, "0");
    let minutes = String(now.getMinutes()).padStart(2, "0");
    let seconds = String(now.getSeconds()).padStart(2, "0");
    let current = `${hours}:${minutes}:${seconds}`;
    console.log(current);
    const command = `${bankPort}:03000100191B`;
    console.log(command);
    client.publish("DEVICE0012", command, { qos });
  };
  const handleNah = () => {
    console.log("nahhhhhh");
    const command = `${bankPort}:030101001A223B`;
    console.log(command);
    client.publish("DEVICE0012", command, { qos });
  };
  const handleRollIn = () => {
    console.log("roll in");
    const command = `${bankPort}:030101001A1108`;
    console.log(command);
    client.publish("DEVICE0012", command, { qos });
  };
  const handleReturnMoney = () => {
    console.log("return money");
    const command = `${bankPort}:030101001C011E`;
    console.log(command);
    client.publish("DEVICE0012", command, { qos });
  };
  const handleLightBox = () => {
    console.log("return money");
    const command = `${bankPort}:FF010111000000EE`;
    console.log(command);
    client.publish("DEVICE0012", command, { qos });
  };
  const handleCountNumberOfReturn = () => {
    console.log("number of return money");
    const command = `${bankPort}:030001001B19`;
    console.log(command);
    client.publish("DEVICE0012", command, { qos });
  };

  // HANDLE FORMAT NUMBER
  const handleFormat = (number) => {
    // Handle edge cases where number is not a valid number
    if (isNaN(number) || number === "") {
      return number;
    }
    return new Intl.NumberFormat().format(number);
  };

  const handleChangeReturnedValue = () => {
    const parsedValue = parseInt(value.replace(/,/g, ""), 10);
    if (allowedValues.includes(parsedValue)) {
      setValueStatus(`Set ${parsedValue / 25000}GT successfully`);
    } else {
      setValueStatus(`Set ${parsedValue / 25000}GT false`);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Remove commas for easier handling of the numeric value
    const numericValue = inputValue.replace(/,/g, "");
    // Update the value state with the raw input value
    setValue(numericValue);
  };

  return (
    <div>
      Banknote controller
      {/* <h1>{t("scan")}</h1>
      <p>{t("homepageHeadline")}</p> */}
      <div>
        <input
          type="text"
          value={handleFormat(value)}
          onChange={handleInputChange}
        />
        <button onClick={handleChangeReturnedValue}>save returned money</button>
        <div>{valueStatus}</div>
      </div>
      <div>
        <h1>Poll Finder</h1>
        {pollValue !== null && (
          <div>
            <h2>Result {totalValue}</h2>
          </div>
        )}
      </div>
      <div>{`poll value:${pollValue}`}</div>
      <div>{`roll value:${rollingValue}`}</div>
      <button onClick={handleReset}>Reset banknote</button>
      <button onClick={handleAccept}>Accept money</button>
      <button onClick={handleSetReturn}>Set return money</button>
      <button onClick={handlePoll}>Read money</button>
      <button onClick={handleNah}>Nah money</button>
      <button onClick={handleRollIn}>Rolling money</button>
      <button onClick={handleReturnMoney}>Return money</button>
      <button onClick={handleCountNumberOfReturn}>
        Number of returned money
      </button>
      <button onClick={handleLightBox}>Light box</button>
      <div style={{ fontSize: 40 }}>money status:{rollingValue}</div>
      <div style={{ fontSize: 40 }}>money value:{totalValue}</div>
      <div style={{ fontSize: 40 }}>
        returned money amount:{moneyReturnTotal}
      </div>
      <div style={{ fontSize: 40 }}>total value:{moneyReturnTotal * 25000}</div>
      <div>Hiện có: {total}</div>
    </div>
  );
}
