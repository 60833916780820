import React, { useEffect, useRef, useState } from "react";
import "../../scss/AdminBoxes.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import mqtt from "mqtt";

export default function BoxesControl() {
  const [isOpenBox, setIsOpenBox] = useState(false);
  const [isOpenAll, setIsOpenAll] = useState(false);
  const [userPIN, setUserPIN] = useState(0);
  const [isPIN, setIsPIN] = useState("");
  const [slot, setSlot] = useState(0);
  const [machineId, setMachineId] = useState("");
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  const { state } = useLocation();
  const userInfo = state?.userInfo;

  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  const serialPort = "/dev/ttyS5";
  const bankPort = "/dev/ttyS3";

  let allSlots = [
    { productSlot: 1 },
    { productSlot: 2 },
    { productSlot: 3 },
    { productSlot: 4 },
    { productSlot: 5 },
    { productSlot: 6 },
    { productSlot: 7 },
    { productSlot: 8 },
    { productSlot: 9 },
    { productSlot: 10 },
    { productSlot: 11 },
    { productSlot: 12 },
    { productSlot: 13 },
    { productSlot: 14 },
    { productSlot: 15 },
    { productSlot: 16 },
    { productSlot: 17 },
    { productSlot: 18 },
    { productSlot: 19 },
    { productSlot: 20 },
    { productSlot: 21 },
    { productSlot: 22 },
    { productSlot: 23 },
    { productSlot: 24 },
  ];

  useEffect(() => {
    const machineIdStoredData = localStorage.getItem("phyf26idk4");
    if (machineIdStoredData) {
      setMachineId(machineIdStoredData);
    }
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopicStorage(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopicStorage(pubTopicStoredData);
    }
  }, []);

  //MQTT COMMUNICATION
  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopicStorage, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [pubTopicStorage, subTopicStorage]);

  // Convert a hexadecimal string to an array of bytes
  const hexStringToBytes = (hex) => {
    return hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16));
  };

  // Calculate checksum using XOR on an array of bytes
  const checksumXOR = (bytes) => {
    return bytes.reduce(
      (accumulator, currentValue) => accumulator ^ currentValue,
      0
    );
  };

  // Convert a byte to a two-character hexadecimal string, padded with leading zero if necessary
  const toHexString = (byte) => {
    return byte.toString(16).toUpperCase().padStart(2, "0");
  };

  // Build command string for a given slot
  const buildCommandSend = (slot) => {
    // Convert slot number to a two-character hexadecimal string, padded with leading zero if necessary
    const formattedSlot = parseInt(slot)
      .toString(16)
      .toUpperCase()
      .padStart(2, "0");

    // Construct the hex string with the given slot
    const hexString = `FF01${formattedSlot}02000000`;

    // Convert the hex string to an array of bytes
    const bytes = hexStringToBytes(hexString);

    // Calculate the checksum using XOR
    const checksum = checksumXOR(bytes);

    // Return the complete command string with the checksum appended
    return `${hexString}${toHexString(checksum)}`;
  };

  //OPEN BOX BY SLOT
  const handleOpenBox = async (slot) => {
    if (userPIN == userInfo.userPIN) {
      try {
        // const commandSend = buildCommandSend(slot);
        const commandSend = `${serialPort}:${buildCommandSend(slot)}`;
        console.log(commandSend);
        client.publish(pubTopicStorage, commandSend, { qos });
        setIsOpenBox(false);
        setUserPIN(0);
        setIsPIN("");
        setIsOpenBox(false);
      } catch (error) {
        console.error("Error sending command:", error);
      }
      setIsOpenBox(false);
      setUserPIN(0);
      setIsPIN("");
    } else {
      setIsPIN("Incorrect PIN. Please try again!");
      setUserPIN(0);
    }
  };

  //OPEN ALL BOXES
  const handleOpenAllBoxes = () => {
    if (userPIN == userInfo.userPIN) {
      console.log(`ALL BOXES IS OPEN`);
      for (let index = 1; index < 25; index++) {
        setTimeout(() => {
          const command = `${serialPort}:${buildCommandSend(index)}`;
          client.publish(pubTopicStorage, command, { qos });
          console.log(command);
        }, 2000 * index);
      }
      setIsOpenAll(false);
      setUserPIN(0);
      setIsPIN("");
    } else {
      setIsPIN("Incorrect PIN. Please try again!");
      setUserPIN(0);
    }
  };

  return (
    <div className="boxes__container">
      <div className="boxes__header">
        <div
          onClick={() =>
            navigateToPage("/admin-management", {
              userInfo: userInfo,
            })
          }
          className="header__back"
        >
          <FaAngleLeft className="header__back_icon" />
        </div>
        <div className="header__title">Điều khiển ngăn</div>
      </div>
      <div className="boxes__content">
        <div className="boxes__list">
          {allSlots.map((item, index) => {
            let displayText = item.productSlot;
            if (item.productSlot === 10) {
              displayText = "Màn hình";
            } else if (item.productSlot === 11) {
              displayText = "Bank note";
            }

            return (
              <div
                key={index}
                className="box__number"
                onClick={() => {
                  setIsOpenBox(true);
                  setSlot(item.productSlot);
                }}
              >
                <div className="number">{displayText}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="open__all">
        <div className="open__all_text" onClick={() => setIsOpenAll(true)}>
          Mở tất cả
        </div>
      </div>
      {isOpenBox ? (
        <div className="modal">
          <div className="modal__content">
            <div className="open__container">
              <div className="open__header">
                Vui lòng nhập mã PIN - {slot} <br />
                <span className="open__header_recommend">
                  Mã PIN bao gồm 6 ký tự
                </span>
              </div>
              <div className="open__pin">
                <input
                  type="password"
                  placeholder="enter your PIN"
                  value={userPIN}
                  onChange={(e) => setUserPIN(e.target.value)}
                />
                <div className="open__pin_status">{isPIN}</div>
              </div>
              <div className="open__controller">
                <div
                  className="open__btn cancel"
                  onClick={() => setIsOpenBox(false)}
                >
                  Thoát
                </div>
                <div
                  className="open__btn confirm"
                  onClick={() => handleOpenBox(slot)}
                >
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isOpenAll ? (
        <div className="modal">
          <div className="modal__content">
            <div className="open__container">
              <div className="open__header">
                Vui lòng nhập mã PIN
                <br />
                <span className="open__header_recommend">
                  Mã PIN bao gồm 6 ký tự
                </span>
              </div>
              <div className="open__pin">
                <input
                  type="password"
                  placeholder="enter your PIN"
                  value={userPIN}
                  onChange={(e) => setUserPIN(e.target.value)}
                />
                <div className="open__pin_status">{isPIN}</div>
              </div>
              <div className="open__controller">
                <div
                  className="open__btn cancel"
                  onClick={() => setIsOpenAll(false)}
                >
                  Thoát
                </div>
                <div className="open__btn confirm" onClick={handleOpenAllBoxes}>
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
