import React, { useEffect, useState } from "react";
import "../../scss/AdminMQTT.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import mqtt from "mqtt";

export default function TechnicalConfig() {
  //MQTT
  const [subTopic, setSubTopic] = useState("");
  const [pubTopic, setPubTopic] = useState("");
  const [navStatus, setNavStatus] = useState(0);
  const [mqttConfig, setMqttConfig] = useState(false);
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [connectedText, setConnectedText] = useState("");

  //PHONE NUMBER
  const [phone, setPhone] = useState("");
  const [phoneId, setPhoneId] = useState(0);
  const [isPhoneConfig, setIsPhoneConfig] = useState(false);

  const bankPort = "/dev/ttyS3";

  // const DBurl = "http://localhost:8080";
  // const DBurl = "https://vending-api.gtcmall.com";
  const DBurl = "https://vending-machine-api.gtcmall.com";

  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };
  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;
  //MQTT COMMUNICATION
  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopic, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [pubTopic, subTopic]);

  useEffect(() => {
    // Load data from local storage on component mount
    handleLoadStorage();
    //loading phone number
    handleGetPhone();
  }, []);

  useEffect(() => {
    if (response === "/dev/ttyS3:010003000103") {
      setIsConnected(true);
      setConnectedText("kết nối thành công");
    } else {
      setConnectedText("kết nối thất bại");
    }
  }, [response]);

  const handleLoadStorage = () => {
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopic(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopic(pubTopicStoredData);
    }
  };

  const handleSaveTopic = () => {
    localStorage.setItem("mbar5xru2b", subTopic);
    localStorage.setItem("rhx8r5mbdi", pubTopic);
    setMqttConfig(false);
    handleLoadStorage();
  };

  const handleGetPhone = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getPhoneNumber`
      );
      if (res.data.success) {
        res.data.result.map((item) => {
          setPhone(item.number);
          setPhoneId(item.id);
        });
      }
    } catch (err) {
      console.error("Failed to retrieve phone number", err);
    }
  };

  const handleSavePhone = async () => {
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateErrorHandle/${phoneId}`,
        {
          number: phone,
        }
      );
      console.log(res.data);
      setIsPhoneConfig(false);
      handleGetPhone();
    } catch (error) {
      console.error("Error when changing phone number", error);
    }
  };

  const handleFormatPhoneNumber = (number) => {
    let formattedNumber = number.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    return formattedNumber;
  };

  const handleReset = () => {
    console.log("reset");
    const command = `${bankPort}:030001000A08`;
    console.log(command);
    client.publish(pubTopic, command, { qos });
  };
  return (
    <div className="technical__container">
      <div className="tech__header">cấu hình kỹ thuật</div>
      <div className="tech__navbar">
        <div
          className={`navbar__item ${navStatus === 0 ? "active" : ""}`}
          onClick={() => setNavStatus(0)}
        >
          mqtt
        </div>
        <div
          className={`navbar__item ${navStatus === 1 ? "active" : ""}`}
          onClick={() => setNavStatus(1)}
        >
          Số điện thoại giải quyết vấn đề
        </div>
      </div>
      <div className="tech__content">
        {navStatus === 0 ? (
          <div className="tech__item mqtt">
            <div className="item__input">
              <div className="item__input_headline">Subscribe Topic</div>
              <input
                placeholder="enter topic"
                value={subTopic}
                onChange={(e) => setSubTopic(e.target.value)}
              />
            </div>
            <div className="item__input">
              <div className="item__input_headline">Publish Topic</div>
              <input
                placeholder="enter topic"
                value={pubTopic}
                onChange={(e) => setPubTopic(e.target.value)}
              />
            </div>
            <div onClick={() => setMqttConfig(true)} className="item__btn_save">
              lưu thông tin cấu hình
            </div>
          </div>
        ) : navStatus === 1 ? (
          <div className="tech__item phone">
            <div className="item__input">
              <div className="item__input_headline">
                Số điện thoại giải quyết sự cố
              </div>
              <input
                placeholder="enter topic"
                value={handleFormatPhoneNumber(phone.toString())}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div
              onClick={() => setIsPhoneConfig(true)}
              className="item__btn_save"
            >
              lưu số điện thoại
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {mqttConfig ? (
        <div className="tech__confirm">
          <div className="tech__confirm_content">
            <div className="tech__confirm_headline">lưu các thông tin trên</div>
            <div className="tech__confirm_btns">
              <div
                className="btn__item cancel"
                onClick={() => {
                  setMqttConfig(false);
                  handleLoadStorage();
                }}
              >
                Thoát
              </div>
              <div className="btn__item confirm" onClick={handleSaveTopic}>
                Đồng ý
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isPhoneConfig ? (
        <div className="tech__confirm">
          <div className="tech__confirm_content">
            <div className="tech__confirm_headline">
              Bạn muốn thay đổi số điện thoại giải quyết vấn đề thành số
            </div>
            <div className="phone">
              {handleFormatPhoneNumber(phone.toString())}
            </div>
            <div className="tech__confirm_btns">
              <div
                className="btn__item cancel"
                onClick={() => {
                  setIsPhoneConfig(false);
                }}
              >
                Thoát
              </div>
              <div className="btn__item confirm" onClick={handleSavePhone}>
                Đồng ý
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isConnected ? (
        <div className="tech__confirm">
          <div className="tech__confirm_content">
            <div className="tech__confirm_headline">{connectedText}</div>
            <div className="tech__confirm_btns">
              <div
                className="btn__item cancel"
                onClick={() => {
                  setIsConnected(false);
                  setResponse("");
                }}
              >
                Thoát
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        onClick={() => {
          navigateToPage("/admin-manage-login");
        }}
        className="logout"
      >
        <div>log out</div>
      </div>
      <div className="test__connection" onClick={handleReset}>
        Test Connection
      </div>
    </div>
  );
}
