import React, { useEffect, useState } from "react";
import "../../scss/AdminMachine.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import axios from "axios";

export default function MachineConfig() {
  const [agencyStorage, setAgencyStorage] = useState(0);
  const [machineStorage, setMachineStorage] = useState("");
  const [configAlert, setConfigAlert] = useState(false);
  const [phone, setPhone] = useState("");
  const [topUpStorage, setTopUpStorage] = useState(0);

  const { state } = useLocation();
  const userInfo = state?.userInfo;

  const navigate = useNavigate();

  // const DBurl = "https://vending-api.gtcmall.com";
  const DBurl = "https://vending-machine-api.gtcmall.com";

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };
  useEffect(() => {
    localStorage.setItem("178yoch4dj", topUpStorage);
    // Load data from local storage on component mount
    const agencyIdStoredData = localStorage.getItem("61w2ujrf3t");
    if (agencyIdStoredData) {
      setAgencyStorage(agencyIdStoredData);
    }
    const machineIdStoredData = localStorage.getItem("phyf26idk4");
    if (machineIdStoredData) {
      setMachineStorage(machineIdStoredData);
    }
    handleGetPhone();
  }, []);

  const handleChangeStorage = () => {
    localStorage.setItem("61w2ujrf3t", agencyStorage);
    localStorage.setItem("phyf26idk4", machineStorage);
    setConfigAlert(false);
  };

  const handleGetPhone = async () => {
    try {
      const res = await axios.get(
        `${DBurl}/vending-machine-api/getPhoneNumber`
      );
      if (res.data.success) {
        res.data.result.map((item) => {
          setPhone(item.number);
        });
      }
    } catch (err) {
      console.error("Failed to retrieve phone number", err);
    }
  };

  const handleFormatPhoneNumber = (number) => {
    let formattedNumber = number.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    return formattedNumber;
  };
  return (
    <div className="machine__container">
      <div className="machine__header">
        <div
          onClick={() =>
            navigateToPage("/admin-management", {
              userInfo: userInfo,
            })
          }
          className="header__back"
        >
          <FaAngleLeft className="header__back_icon" />
        </div>
        <div className="header__title">Thiết lập máy bán hàng</div>
      </div>
      <div className="machine__content">
        <div className="form">
          <div className="form__item">
            <label className="form__item_label">Agency ID</label>
            <input
              placeholder="enter agency id"
              value={agencyStorage}
              onChange={(e) => setAgencyStorage(e.target.value)}
            />
          </div>
          <div className="form__item">
            <label className="form__item_label">Machine ID</label>
            <input
              placeholder="enter machine id"
              value={machineStorage}
              onChange={(e) => setMachineStorage(e.target.value)}
            />
          </div>
        </div>
        <div className="submit__btn" onClick={() => setConfigAlert(true)}>
          Lưu thông tin
        </div>
      </div>
      {configAlert ? (
        <div className="config__container">
          <div className="config__content">
            <div className="config__headline">Vui lòng chú ý!</div>
            <div className="config__item">
              Những thông tin này sẽ ảnh hưởng trực tiếp đến quá trình hoạt động
              của máy
            </div>
            <div className="config__item">
              Hãy đảm bảo những thông tin này chính xác trước khi lưu
            </div>
            <div className="config__item">
              Nếu như bạn đã chắc chắn, vui lòng bấm LƯU để lưu lại thông tin
              trên.
            </div>
            <div className="config__contact">
              Để biết thêm thông tin, vui lòng liên hệ quản trị viên theo số:{" "}
              <br />
              {/* 0816 666 722 */}
              {handleFormatPhoneNumber(phone.toString())}
            </div>
            <div className="config__btn_container">
              <div
                className="config__btn cancel"
                onClick={() => setConfigAlert(false)}
              >
                Thoát
              </div>
              <div
                className="config__btn confirm"
                onClick={handleChangeStorage}
              >
                LƯU
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
