import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaList,
  FaMoneyBill,
  FaAngleLeft,
  FaCheck,
  FaChevronCircleLeft,
} from "react-icons/fa";
import "../../scss/PaymentMethod.scss";
import CountDown from "../CountDown";
import mqtt from "mqtt";
import axios from "axios";

export default function CashPage() {
  const [compelteTrans, setCompleteTrans] = useState(false);
  const [transStatus, setTransStatus] = useState(null);
  const [createTransStatus, setCreateTransStatus] = useState(null);
  const [transInfo, setTransInfo] = useState({});
  const [createOrder, setCreateOrder] = useState(false);
  const [topUp, setTopUp] = useState(0);
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [startInterval, setStartInterval] = useState(false);
  const [numberA, setNumerA] = useState(0);
  const [b, setB] = useState(10); // Use state for b
  const [pollValue, setPollValue] = useState("");
  const [moneyValue, setMoneyValue] = useState("");
  const [moneyStatus, setMoneyStatus] = useState(0);
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");
  const [withDraw, setWithDraw] = useState(false);
  const [takeCash, setTakeCash] = useState(false);
  const [cashAmount, setCashAmount] = useState(0);
  const [cashErr, setCashErr] = useState("");

  const navigate = useNavigate();

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  const DBurl = "https://vending-machine-api.gtcmall.com";

  const { state } = useLocation();
  // const transactionDetail = state?.transactionDetail;
  const productInfo = state?.productInfo;
  const machineId = state?.machineId;
  const agencyId = state?.agencyId;
  const token = state?.token;
  const balance = state?.balance;

  const bankPort = "/dev/ttyS3";

  const pollList = [
    {
      id: "00",
      value: 0,
    },
    {
      id: "01",
      value: 0.04,
    },
    {
      id: "02",
      value: 0.08,
    },
    {
      id: "03",
      value: 0.2,
    },
    {
      id: "04",
      value: 0.4,
    },
    {
      id: "05",
      value: 0.8,
    },
    {
      id: "06",
      value: 2,
    },
    {
      id: "07",
      value: 4,
    },
    {
      id: "08",
      value: 8,
    },
    {
      id: "09",
      value: 20,
    },
  ];

  useEffect(() => {
    // Load data from local storage on component mount
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopicStorage(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopicStorage(pubTopicStoredData);
    }
  }, []);

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  //MQTT COMMUNICATION
  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopicStorage, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        setNumerA(1);
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [pubTopicStorage, subTopicStorage]);
  //ACCEPT MONEY WHEN MQTT CONNECTED
  useEffect(() => {
    if (client) {
      handleAccept();
    }
  }, [client]);
  //READ MONEY FUNCTIONAL
  useEffect(() => {
    let intervalId;
    if (startInterval) {
      intervalId = handleReadMoneyValue();
      setMoneyStatus(0);
    }
    // Cleanup interval on component unmount or if startInterval changes
    return () => clearInterval(intervalId);
  }, [startInterval, numberA, b]);
  //WHEN MONEY INSERTED
  useEffect(() => {
    if (response === "/dev/ttyS3:010003000103") {
      handleStackMoney(pollValue);
    }
  }, [response, pollValue]);
  //HANDLE CREATE TRANSACTION
  useEffect(() => {
    if (createOrder) {
      handlePurchase();
    }
  }, [createOrder]);
  //HANDLE COMPLETE TRANSACTION
  useEffect(() => {
    if (createTransStatus) {
      handleCheckOut();
    }
  }, [createTransStatus]);
  //WHEN TRANSACTION SUCCESSFULLY MOVE TO RESULT PAGE
  useEffect(() => {
    if (transStatus) {
      navigateToPage("/trans-completed", {
        agencyId: agencyId,
        machineId: machineId,
        value: productInfo.productPrice + (topUp - productInfo.productPrice),
        token: token,
        balance: transInfo.balance,
        productInfo: productInfo,
        cashAmount: cashAmount,
      });
    }
  }, [transStatus]);
  //CALCULATE MONEY
  const handleStackMoney = (poll) => {
    const result = pollList.find((item) => item.id === poll);
    if (result) {
      setTopUp((prevTotal) => prevTotal + result.value);
      setMoneyStatus(2);
    } else {
      console.log(`No matching value found for id ${poll}`);
    }
  };
  //GET MATHCHING VALUE
  const handleFindMoneyValue = (poll) => {
    const result = pollList.find((item) => item.id === poll);
    if (result) {
      setMoneyValue(result.value);
      setMoneyStatus(1);
    } else {
      console.log(`No matching value found for id ${poll}`);
    }
  };
  //READ MONEY AND STACK
  const handleReadMoneyValue = () => {
    return setInterval(async () => {
      if (b === 10) {
        await handlePoll();
        setB(0);
      }
      if (numberA === 1) {
        if (response !== null && response.length === 49) {
          const moneyValue = response.substring(25, 27);
          const moneyStatus = response.substring(23, 25);
          if (moneyStatus === "00" && moneyValue !== "00") {
            handleRollIn();
            handleFindMoneyValue(moneyValue);
            setPollValue(moneyValue);
          }
        }
        setNumerA(0);
        setB(8);
      } else {
        setB((prevB) => prevB + 1);
      }
    }, 1000);
  };
  //HANDLE ACCEPT MONEY
  const handleAccept = () => {
    console.log("accept");
    const command = `${bankPort}:0302010015FFFF15`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
    setTimeout(() => {
      handleSetReturn();
      setTimeout(() => {
        setStartInterval(true);
        setNumerA(0);
      }, 5000);
    }, 5000);
  };
  //HANDLE WITHDRAW MONEY
  const handleSetReturn = () => {
    console.log("set return money");
    const command = `${bankPort}:0301010017091D`;
    console.log(command);
    client.publish("DEVICE", command, { qos });
  };
  //HANDLE READ MONEY
  const handlePoll = () => {
    console.log("poll");
    const now = new Date();
    let hours = String(now.getHours()).padStart(2, "0");
    let minutes = String(now.getMinutes()).padStart(2, "0");
    let seconds = String(now.getSeconds()).padStart(2, "0");
    let current = `${hours}:${minutes}:${seconds}`;
    console.log(current);
    const command = `${bankPort}:03000100191B`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
  };
  //HANDLE STACK MONEY
  const handleRollIn = () => {
    console.log("roll in");
    const command = `${bankPort}:030101001A1108`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
  };
  //GET CURRENT TIME DATE
  const getCurrentTime = () => {
    let currentDateTime = new Date();

    // Get the year, month, and day components
    let year = currentDateTime.getFullYear();
    // Months are zero-based, so we add 1 to get the correct month
    let month = currentDateTime.getMonth() + 1;
    let day = currentDateTime.getDate();

    // Pad single-digit months and days with a leading zero if needed
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Get the hour, minute, and second components
    let hour = currentDateTime.getHours();
    let minute = currentDateTime.getMinutes();
    let second = currentDateTime.getSeconds();

    // Pad single-digit hour, minute, and second with a leading zero if needed
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    let formattedTime = `${hour}:${minute}:${second}`;

    let time = `${year}-${month}-${day} ${formattedTime}`;

    return time;
  };
  //HANDLE FORMAT NUMBER
  const handelFormat = (number) => {
    return new Intl.NumberFormat().format(number);
  };
  //HANDLE CONVERT REMAINING TO GT PONINTS
  const handleConvertGTC = () => {
    setCreateOrder(true);
    setWithDraw(false);
  };
  //GTC PURCHASE V2
  const handlePurchase = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/purchase-v2`, {
        token: token,
        agencyId: agencyId,
        machineId: machineId,
        slot: productInfo.productSlot,
        value: productInfo.productPrice,
        ogprice: productInfo.productOgPrice,
      });
      setCreateTransStatus(res.data.result.success);
      setTransInfo(res.data.result.result.purchaseRequestId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //GTC RESULT
  const handleCheckOut = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/result`, {
        purchaseRequestId: transInfo,
        machineId: machineId,
        transaction: 1,
        slot: productInfo.productSlot,
        value: productInfo.productPrice,
        paymentMethod: 0,
        status: 1,
        totalSale: 1,
        totalMoneySale: productInfo.productPrice,
        time: getCurrentTime(),
      });
      console.log(res.data.result.success);
      setTransStatus(res.data.result.success);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //HANDLE WITHDRAW
  const handleWithDraw = () => {
    if (cashAmount * 25000 <= 100000) {
      setCashErr(`Chỉ có thể trả tiền mệnh giá 100,000 VND`);
    } else if ((cashAmount * 25000) % 100000 !== 0) {
      setCashErr("Giá trị phải là bội của \n 100,000");
    } else if (cashAmount * 25000 > 5000000) {
      setCashErr("Vượt quá giá trị tối đa (5,000,000 VND)");
    } else {
      setCreateOrder(true);
      setTakeCash(false);
    }
  };

  return (
    <div className="method__container">
      <div className="method__header">
        <div
          onClick={() =>
            navigateToPage(`/detail/:${productInfo.productId}`, {
              productInfo: productInfo,
              machineId: machineId,
              agencyId: agencyId,
              token: token,
              balance: balance,
            })
          }
          className="header__back"
        >
          <FaAngleLeft className="header__back_icon" />
        </div>
        <div className="header__title">Thanh toán - Tiền mặt</div>
      </div>
      <div className="method__content">
        <div className="method__summary">
          <div className="method__summary_content">
            <img
              src={productInfo.productImage}
              alt={productInfo.productName}
              className="summary__image"
            />
            <div className="summary__info">
              <div className="summary__price">
                <div className="info__item">{productInfo.productName}</div>
                <div className="info__item">
                  {handelFormat(productInfo.productPrice)}
                  <span className="currency">GT</span>
                </div>
              </div>
              <div className="summary__line"></div>
              <div className="summary__total">
                <div className="info__total">Tổng</div>
                <div className="info__total">
                  {handelFormat(productInfo.productPrice)}
                  <span className="currency">GT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="method__transaction">
          <div className="method__cash_topup">
            <div className="method__cash_topup_headline">Nạp</div>
            <div className="method__cash_topup_number">
              {handelFormat(topUp)}
              <span>GT</span>
            </div>
          </div>
          {topUp - productInfo.productPrice >= 0 ? (
            <>
              {/* <div className="method__cash_notice">
                Please notice!
                <br />
                The remaining money will be sent into <br /> your GTC account
                after the transaction completed
              </div> */}
              <div
                onClick={() => {
                  setWithDraw(true);
                }}
                className="method__cash_enough"
              >
                tiếp tục
              </div>
            </>
          ) : (
            <>
              <div className="method__cash_notice">
                Quá trình có thể mất vài giây để xử lý <br />
                Cảm ơn bạn đã kiên nhẫn !
              </div>
              <div className="method__cash">
                <div className="layer__faded">
                  <div
                    className={`money__reader ${
                      moneyStatus === 0
                        ? ""
                        : moneyStatus === 1
                        ? "display"
                        : "faded"
                    }`}
                  >
                    {handelFormat(moneyValue)} GT
                  </div>
                </div>
                <div className="banknote"></div>
                <div className="layer__faded_in"></div>
                <div className="cash">
                  <FaMoneyBill />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* withdraw */}
      {withDraw && (
        <div className="withdraw">
          <div className="withdraw__container">
            <div className="withdraw__content">
              <div className="withdraw__header">
                <div className="header__headline">Số tiền còn lại</div>
                <div className="header__remain">
                  {handelFormat(topUp - productInfo.productPrice)} GT
                </div>
              </div>
              <div className="withdraw__btns">
                <div className="btn__item" onClick={handleConvertGTC}>
                  <div className="btn__gtc "> Chuyển thành điểm GT</div>
                </div>
                <div className="btn__item">
                  <div
                    className="btn__cash"
                    onClick={() => {
                      setTakeCash(true);
                      setWithDraw(false);
                    }}
                  >
                    Lấy tiền mặt
                  </div>
                  <div className="btn__cash_text">
                    Máy có thể trả tối thiểu 4GT
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* get cash */}
      {takeCash && (
        <div className="take__cash">
          <div className="take__cash_container">
            <div className="take__cash_content">
              <div className="take__cash_nav">
                <div
                  onClick={() => {
                    setTakeCash(false);
                    setWithDraw(true);
                  }}
                  className="nav__arrow"
                >
                  <FaAngleLeft className="nav__icon" />
                </div>
                <div className="nav__title">Rút tiền</div>
              </div>
              <div className="take__cash_line"></div>
              <div className="take__cash_summary">
                <div className="summary__item">
                  <div className="headline">Còn lại</div>
                  <div className="number">
                    {handelFormat(topUp - productInfo.productPrice)}
                    <span className="currency">GT</span>
                  </div>
                </div>
                <div className="summary__item">
                  <div className="headline">Điểm GT</div>
                  <div className="number">
                    {handelFormat(balance)}
                    <span className="currency">GT</span>
                  </div>
                </div>
              </div>
              <div className="take__cash_line"></div>
              <div className="take__cash_amount">
                <div className="amount__headline">Rút tiền</div>
                <div className="amount__input">
                  <input
                    value={handelFormat(cashAmount)}
                    onChange={(e) =>
                      setCashAmount(Number(e.target.value.replace(/\D/g, "")))
                    }
                    className={`input ${cashErr !== "" ? "err" : "success"}`}
                  />
                  <span className="currency">GT</span>
                </div>
              </div>
              <div className="take__cash_amount_err">{cashErr}</div>
              <div className="take__cash_btn" onClick={handleWithDraw}>
                Rút tiền ngay
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="state">
        <div className="state__item done">
          <FaCheck className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item active">
          <FaMoneyBill className="state__icon" />
        </div>
        <div className="state__line"></div>
        <div className="state__item ">
          <FaList className="state__icon" />
        </div>
      </div>
      <div className="method__waiting">
        <div className="method__waiting_text">Đợi giao dịch hoàn tất</div>
        <div className="count__down">
          <CountDown
            pathPage={"/scanqrcode"}
            data={{
              machineId: machineId,
              agencyId: agencyId,
              topUp: topUp,
              token: token,
            }}
          />
        </div>
      </div>
      {createOrder ? (
        <div className="loading__container">
          <div className="loading__text">
            Vui lòng đợi
            <br />
            giao dịch được hoàn tất
          </div>
          <div className="dots">
            <div className="dot first"></div>
            <div className="dot second"></div>
            <div className="dot third"></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
