import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../scss/Cashing.scss";
import {
  FaList,
  FaMoneyBill,
  FaAngleLeft,
  FaCheck,
  FaChevronCircleLeft,
  FaExclamation,
} from "react-icons/fa";
import "../../scss/PaymentMethod.scss";
import CountDown from "../CountDown";
import mqtt from "mqtt";
import axios from "axios";

export default function TopUp() {
  const [createTransStatus, setCreateTransStatus] = useState(null);
  const [transInfo, setTransInfo] = useState(0);
  const [createOrder, setCreateOrder] = useState(false);
  // const [topUp, setTopUp] = useState(0);
  const [client, setClient] = useState(null);
  const [qos, setQos] = useState(2);
  const [response, setResponse] = useState(null);
  const [startInterval, setStartInterval] = useState(false);
  const [numberA, setNumerA] = useState(0);
  const [b, setB] = useState(10); // Use state for b
  const [pollValue, setPollValue] = useState("");
  const [moneyValue, setMoneyValue] = useState("");
  const [moneyStatus, setMoneyStatus] = useState(0);
  const [subTopicStorage, setSubTopicStorage] = useState("");
  const [pubTopicStorage, setPubTopicStorage] = useState("");
  const [isTopUp, setIsTopUp] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [transactionId, setTransactionId] = useState(0);
  const [topUpStorage, setTopUpStorage] = useState(0);
  const [stackMoney, setStackMoney] = useState(false);

  const navigate = useNavigate();

  const { state } = useLocation();
  const machineId = state?.machineId;
  const agencyId = state?.agencyId;
  const token = state?.token;
  const balance = state?.balance;
  const machineInfo = state?.machineInfo;

  const navigateToPage = (pageUrl, stateData) => {
    navigate(pageUrl, { state: stateData });
  };

  const bankPort = "/dev/ttyS3";

  // const DBurl = "https://vending-api.gtcmall.com";
  // const DBurl = "http://localhost:8080";
  const DBurl = "https://vending-machine-api.gtcmall.com";

  const pollList = [
    {
      id: "00",
      value: 0,
    },
    {
      id: "01",
      value: 0.04,
    },
    {
      id: "02",
      value: 0.08,
    },
    {
      id: "03",
      value: 0.2,
    },
    {
      id: "04",
      value: 0.4,
    },
    {
      id: "05",
      value: 0.8,
    },
    {
      id: "06",
      value: 2,
    },
    {
      id: "07",
      value: 4,
    },
    {
      id: "08",
      value: 8,
    },
    {
      id: "09",
      value: 20,
    },
  ];

  useEffect(() => {
    // Load data from local storage on component mount
    const subTopicStoredData = localStorage.getItem("mbar5xru2b");
    if (subTopicStoredData) {
      setSubTopicStorage(subTopicStoredData);
    }
    const pubTopicStoredData = localStorage.getItem("rhx8r5mbdi");
    if (pubTopicStoredData) {
      setPubTopicStorage(pubTopicStoredData);
    }
    const topUpStoragedData = localStorage.getItem("178yoch4dj");
    if (topUpStoragedData) {
      setTopUpStorage(Number(topUpStoragedData));
    }
    handleGTCAPI();
    machineInfo.map((item) => setTransactionId(item.transactionId));
  }, []);

  const brokerConfig = {
    host: "vending-mqtt.gtcmall.com",
    // port: 8803,
    protocol: "wss",
    username: "guest",
    password: "123456a@",
  };
  // BROKER URL
  // const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}:${brokerConfig.port}`;
  const brokerUrl = `${brokerConfig.protocol}://${brokerConfig.host}`;

  //MQTT COMMUNICATION
  useEffect(() => {
    const mqttClient = mqtt.connect(brokerUrl, brokerConfig);
    setClient(mqttClient);
    mqttClient.on("connect", () => {
      console.log("CONNECTED");
      mqttClient.subscribe(subTopicStorage, { qos }, (err) => {
        if (err) {
          console.error(`Error subscribing to topic:`, err);
        }
      });
    });

    mqttClient.on("message", (topic, payload) => {
      try {
        const receivedMessage = payload.toString();
        setResponse(payload.toString());
        setNumerA(1);
        console.log(`Received message on topic ${topic}:`, receivedMessage);
      } catch (error) {
        console.error(`Error parsing JSON message on topic ${topic}:`, error);
      }
    });

    mqttClient.on("error", (error) => {
      console.error("MQTT Error:", error);
    });

    mqttClient.on("close", () => {
      console.log("Connection to MQTT broker closed");
    });

    mqttClient.on("offline", () => {
      console.log("MQTT client is offline");
    });

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [pubTopicStorage, subTopicStorage]);
  //ACCEPT MONEY WHEN MQTT CONNECTED
  useEffect(() => {
    if (client) {
      handleAccept();
    }
  }, [client]);

  useEffect(() => {
    if (stackMoney) {
      localStorage.setItem("178yoch4dj", topUpStorage);
      setStackMoney(false);
    }
  }, [stackMoney]);

  //READ MONEY FUNCTIONAL
  useEffect(() => {
    let intervalId;
    if (startInterval) {
      intervalId = handleReadMoneyValue();
      setMoneyStatus(0);
    }
    // Cleanup interval on component unmount or if startInterval changes
    return () => clearInterval(intervalId);
  }, [startInterval, numberA, b]);
  //WHEN MONEY INSERTED
  useEffect(() => {
    if (response === "/dev/ttyS3:010003000103") {
      handleStackMoney(pollValue);
    }
  }, [response, pollValue]);

  //CALCULATE MONEY
  const handleStackMoney = (poll) => {
    const result = pollList.find((item) => item.id === poll);
    if (result) {
      setTopUpStorage((prevTotal) => prevTotal + result.value);
      // localStorage.setItem("178yoch4dj", topUpStorage);
      setStackMoney(true);
      setMoneyStatus(2);
    } else {
      console.log(`No matching value found for id ${poll}`);
    }
  };
  //GET MATHCHING VALUE
  const handleFindMoneyValue = (poll) => {
    const result = pollList.find((item) => item.id === poll);
    if (result) {
      setMoneyValue(result.value);
      setMoneyStatus(1);
    } else {
      console.log(`No matching value found for id ${poll}`);
    }
  };
  //READ MONEY AND STACK
  const handleReadMoneyValue = () => {
    return setInterval(async () => {
      if (b === 15) {
        await handlePoll();
        setB(0);
      }
      if (numberA === 1) {
        if (response !== null && response.length === 49) {
          const moneyValue = response.substring(25, 27);
          const moneyStatus = response.substring(23, 25);
          if (moneyStatus === "00" && moneyValue !== "00") {
            handleRollIn();
            handleFindMoneyValue(moneyValue);
            setPollValue(moneyValue);
          }
        }
        setNumerA(0);
        setB(12);
      } else {
        setB((prevB) => prevB + 1);
      }
    }, 1000);
  };
  //HANDLE ACCEPT MONEY
  const handleAccept = () => {
    console.log("accept");
    const command = `${bankPort}:0302010015FFFF15`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
    setTimeout(() => {
      setStartInterval(true);
      setNumerA(0);
    }, 5000);
  };
  //HANDLE READ MONEY
  const handlePoll = () => {
    console.log("poll");
    const now = new Date();
    let hours = String(now.getHours()).padStart(2, "0");
    let minutes = String(now.getMinutes()).padStart(2, "0");
    let seconds = String(now.getSeconds()).padStart(2, "0");
    let current = `${hours}:${minutes}:${seconds}`;
    console.log(current);
    const command = `${bankPort}:03000100191B`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
  };
  //HANDLE STACK MONEY
  const handleRollIn = () => {
    console.log("roll in");
    const command = `${bankPort}:030101001A1108`;
    console.log(command);
    client.publish(pubTopicStorage, command, { qos });
  };
  //GET CURRENT TIME DATE
  const getCurrentTime = () => {
    let currentDateTime = new Date();

    // Get the year, month, and day components
    let year = currentDateTime.getFullYear();
    // Months are zero-based, so we add 1 to get the correct month
    let month = currentDateTime.getMonth() + 1;
    let day = currentDateTime.getDate();

    // Pad single-digit months and days with a leading zero if needed
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Get the hour, minute, and second components
    let hour = currentDateTime.getHours();
    let minute = currentDateTime.getMinutes();
    let second = currentDateTime.getSeconds();

    // Pad single-digit hour, minute, and second with a leading zero if needed
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    let formattedTime = `${hour}:${minute}:${second}`;

    let time = `${year}-${month}-${day} ${formattedTime}`;

    return time;
  };
  //HANDLE FORMAT NUMBER
  const handelFormat = (number) => {
    return new Intl.NumberFormat().format(number);
  };
  //HANDLE TOPUP CASH CONVERT TO GT
  const handleTopUp = () => {
    handleTransfer();
    setCreateOrder(true);
  };
  //transfer
  const handleTransfer = async () => {
    const value = parseInt(topUpStorage * 25000);
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/transfer-v2`, {
        token: token,
        value: value,
        machineId: machineId,
        agencyId: agencyId,
        time: getCurrentTime(),
      });
      console.log(res.data.result.success);
      setIsTopUp(res.data.result.success);
      localStorage.setItem("178yoch4dj", Number(0));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //callback
  const handleGTCAPI = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/callback`, {
        token: token,
        balance: balance,
        machineId: machineId,
      });
      setIsTokenValid(res.data.result);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  //logout
  const handleLogOutGTC = async () => {
    try {
      const res = await axios.post(`${DBurl}/gtc/selling-machine/logout`, {
        token: token,
        machineId: machineId,
      });

      console.log(res);
    } catch (error) {
      console.error(`Error when getting machine info ${error}`);
    }
  };
  //HANDLE RESET MACHINE STATUS
  const resetMachineStatus = async () => {
    console.log("RESETED");
    try {
      const res = await axios.put(
        `${DBurl}/vending-machine-api/updateMachineStatus/${machineId}`,
        {
          machineStatus: 0,
        }
      );
      console.log(res.data);
    } catch (err) {
      console.log("Getting an error at: ", err);
    }
  };
  //HANDLE REMOVE TOKEN
  const handleRemoveToken = async () => {
    try {
      const res = await axios.delete(
        `${DBurl}/vending-machine-api/deleteToken`,
        {
          data: {
            token: token,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.error("Error when delete token: ", err);
    }
  };
  return (
    <div className="cashing__container">
      <div className="cashing__header">
        {topUpStorage > 0 ? (
          <div className="header__back disable"></div>
        ) : (
          <div
            onClick={() =>
              navigateToPage(`/homepage`, {
                machineId: machineId,
                agencyId: agencyId,
                token: token,
                balance: balance,
              })
            }
            className="header__back able"
          >
            <FaAngleLeft className="header__back_icon" />
          </div>
        )}
        <div className="header__title">Nạp tiền</div>
      </div>
      <div className="cashing__content">
        <div className="method__transaction">
          <div className="method__cash_summary">
            <div className="method__cash_item">
              <div className="method__cash_topup_headline">Điểm GT</div>
              <div className="method__cash_topup_number">
                {handelFormat(balance)}
                <span className="currency">GT</span>
              </div>
            </div>
            <div className="method__cash_item">
              <div className="method__cash_topup_headline">Nạp vào</div>
              <div className="method__cash_topup_number">
                {handelFormat(topUpStorage)}
                <span className="currency">GT</span>
              </div>
            </div>
            <div className="cashing__line"></div>
            <div className="method__cash_item">
              <div className="method__cash_topup_headline">Tổng</div>
              <div className="method__cash_topup_number">
                {/* {handelFormat(balance + topUpStorage)} */}
                {handelFormat(balance + topUpStorage)}
                <span className="currency">GT</span>
              </div>
            </div>
          </div>
          <div>
            <div className="method__cash_notice">
              Quá trình đọc tiền có thể mất đến vài giây. <br /> Vui lòng đợi
              trong giây lát.
            </div>
            <div className="method__cash">
              <div className="layer__faded">
                <div
                  className={`money__reader ${
                    moneyStatus === 0
                      ? ""
                      : moneyStatus === 1
                      ? "display"
                      : "faded"
                  }`}
                >
                  {handelFormat(moneyValue)} GT
                </div>
              </div>
              <div className="banknote"></div>
              <div className="layer__faded_in"></div>
              <div className="cash">
                <FaMoneyBill />
              </div>
            </div>
          </div>
          {topUpStorage > 0 ? (
            <>
              {createOrder ? (
                <div className="dots">
                  <div className="dot first"></div>
                  <div className="dot second"></div>
                  <div className="dot third"></div>
                </div>
              ) : (
                <div className="cashing__topup_btn" onClick={handleTopUp}>
                  nạp ngay
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {isTopUp !== null ? (
        <div className="cashing__topup_success">
          <div className="topup__success_container">
            <div className="topup__success_text">Nạp tiền thành công</div>
            <div className="topup__success_summary">
              <div className="topup__success_headline">Số điểm GT hiện tại</div>
              <div className="topup__success_number">
                {handelFormat(balance + topUpStorage)}
                <span className="currency">GT</span>
              </div>
            </div>
            <div
              className="topup__success_btn"
              onClick={() => {
                navigateToPage("/scanqrcode");
                resetMachineStatus();
                handleRemoveToken();
                handleLogOutGTC();
              }}
            >
              Đăng xuất
            </div>
            <div className="topup__logout">
              Đăng xuất trong
              <CountDown pathPage={"/scanqrcode"} />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isTokenValid ? (
        <></>
      ) : (
        <div className="valid">
          <div className="valid__content">
            <FaExclamation className="valid__icon" />
            <div className="valid__headline">
              Phiên làm việc đã hết hạn <br /> Vui lòng đăng nhập lại để thực
              hiện giao dịch mới
            </div>
            <div
              className="valid__btn"
              onClick={() => {
                navigateToPage("/scanqrcode");
                resetMachineStatus();
                handleRemoveToken();
              }}
            >
              đăng nhập
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
